import React, { useEffect } from "react";
import {
  Route,
  BrowserRouter as Router,
  Routes
} from "react-router-dom";
import "./App.css";
import AppMusic from "./assets/audio/sound.mp3";
import ProtectedRoute from "./components/ProtectedRoute";
import AboutScreen from "./screens/AboutUs";
import AddToHomeScreen from "./screens/AddHomeScreen";
import AddTemplateScreen from "./screens/AddTemplateScreen";
import BckProjectList from "./screens/BckProjectList";
import LoginScreen from "./screens/LoginScreen";
import MainScreen from "./screens/MainScreen";
import ProjectDetail from "./screens/ProjectDetail";
import ProjectListScreen from "./screens/ProjectListScreen";
import SubProjectScreen from "./screens/SubProjectScreen";

export const RouterContext = React.createContext();

function App() {
  const audioRef = React.useRef();
  useEffect(() => {
    // document.addEventListener('contextmenu', (e) => {
    //   e.preventDefault();
    // });
    try {
      audioRef.current?.play().catch((err) => {
        if (err.name === 'NotAllowedError') {
          console.log('Error playing audio:', err);
        } else {
          throw err;
        }
      });
    } catch (err) {
      console.log('Error playing audio:', err);
    }
    console.log("🌈 Bangkok Bastards LIVE");
  }, []);

  const [audioPlay, setAudio] = React.useState(true);

  useEffect(() => {
    console.log("🌈 🔊 Sound change state", audioPlay);
    if (audioPlay) {
      try {
        audioRef.current?.play().catch((err) => {
          if (err.name === 'NotAllowedError') {
            console.log('Error playing audio:', err);
          } else {
            throw err;
          }
        });
      } catch (err) {
        console.log('Error playing audio:', err);
      }
    } else {
      audioRef && audioRef.current?.pause();
    }
  }, [audioPlay]);
 

  return (
    <RouterContext.Provider value={{ audioPlay, setAudio }}>
      <Router>
        <Routes>
          <Route path="/" element={<MainScreen />} />
          <Route path="/about" element={<AboutScreen />} />
          <Route
            path="/project-list/:category/:id"
            element={<SubProjectScreen />}
          />
          <Route
            path="/project-list/:category"
            element={<ProjectListScreen />}
          />
          <Route path="/project-detail/:id" element={<ProjectDetail />} />
          <Route path="/bcknd" element={<LoginScreen />} />
          <Route
            path="/bcknd/add-template/:id"
            element={
              <ProtectedRoute>
                <AddTemplateScreen />
              </ProtectedRoute>
            }
          />
          <Route
            path="/bcknd/add-template"
            element={
              <ProtectedRoute>
                <AddTemplateScreen />
              </ProtectedRoute>
            }
          />
          <Route
            path="/bcknd/add-home"
            element={
              <ProtectedRoute>
                <AddToHomeScreen />
              </ProtectedRoute>
            }
          />
          <Route
            path="/bcknd/project-list"
            element={
              <ProtectedRoute>
                <BckProjectList />
              </ProtectedRoute>
            }
          />
          <Route path="*" />
        </Routes>
      </Router>
      {!window.location.pathname.includes("bcknd") && (
        <audio autoPlay loop ref={audioRef} id="audio-sound">
          <source src={AppMusic} type="audio/mp3" />
        </audio>
      )}
    </RouterContext.Provider>
  );
}

export default App;

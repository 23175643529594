import axios from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'

const API_URL = process.env.REACT_APP_API_URL

const getProjectService = async (active) => {
  const filter = typeof active === 'boolean' ? `?active=${active}` : ""
  const { data } = await axios.get(`${API_URL}/project${filter}`);
  return data
}

export const getProjectByTypes = async () => {
  const { data } = await axios.get(`${API_URL}/project/types`);
  return data
}

export const getProjectByDocumenters = async () => {
  const { data } = await axios.get(`${API_URL}/project/documenters`);
  return data
}

export const getProjectByMainArea = async () => {
  const { data } = await axios.get(`${API_URL}/project/mainarea`);
  return data
}
export const getProjectBySubArea = async (id) => {
  const { data } = await axios.get(`${API_URL}/project/subarea/${id}`);
  return data
}

const createProjectService = async (payload) => {
  const { data } = await axios.post(`${API_URL}/project/create`, payload)
  return data
}

export const getProjectById = async (id) => {
  const { data } = await axios.get(`${API_URL}/project/${id}`)
  return data
}

export const updateProjectById = async (id, payload) => {
  const { data } = await axios.put(`${API_URL}/project/${id}`, payload)
  return data
}

export const deletedProjectHandler = async (id) => {
  const { data } = await axios.put(`${API_URL}/project/${id}`, { active: false })
  return data
}
export const publishProjectHandler = async (id) => {
  const { data } = await axios.put(`${API_URL}/project/${id}`, { active: true })
  return data
}

export const hardDeleteProjectHandler = async (id) => {
  const { data } = await axios.delete(`${API_URL}/project/${id}`)
  return data
}

export const getProjectByCategory = async () => {
  const { data } = await axios.get(`${API_URL}/project/category`);
  return data
}

// *SECTION thunk 

const fetchProject = createAsyncThunk(
  'getProjects',
  async ({ active = "" }) => {
    const response = await getProjectService(active)
    return response
  }
)

const createProject = createAsyncThunk(
  'createProject',
  async (payload) => {
    const response = await createProjectService(payload)
    return response
  }
)

export { fetchProject, createProject }


import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { t1, t2, t3, updateProjectTemplate } from "../../redux/projectSlice";
import { useDispatch, useSelector } from "react-redux";
import LoadingWithBackDrop from "../Backdrop/LoadingWithBackDrop";
import MuiAlert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import axios from "axios";
import { LoadingButton } from "@mui/lab";

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const Input = styled("input")({
  display: "none",
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function MediaOnlyDialog({
  open,
  handleClose,
  templateId,
  form
}) {
  const dispatch = useDispatch();
  const [fileMobile, setFileMobile] = React.useState(null);
  const [fileDesktop, setFileDesktop] = React.useState(null);
  const [fileModal, setFileModal] = React.useState(null);
  const [fileType, setFileType] = React.useState("image");
  const [textModal, setTextModal] = React.useState("")
  const [isLoading, setIsLoading] = React.useState(false);
  const [snackOpen, setSnackOpen] = React.useState(false);
  const [template, setTemplate] = React.useState(null)

  const [isCheck, toggleIsCheck] = React.useState(true)

  React.useEffect(() => {
    if (Array.isArray(form.values.templateGalleries) && form.values.templateGalleries.length > 0) {
      const data = form.values?.templateGalleries[0]
      setFileDesktop(data?.url_desktop ? { key: data.url_desktop || data._id, Location: data?.url_desktop } : null)
      setFileMobile(data?.url_mobile ? { key: data.url_mobile || data._id, Location: data?.url_mobile } : null)
      setFileModal(data?.modal_image ? { key: data.modal_image || data._id, Location: data?.modal_image } : null)
      toggleIsCheck(data?.use_desktop === undefined ? true : data?.use_desktop)
      setTextModal(data?.modal_caption ?? "")
    } else {
      setFileDesktop(null)
      setFileModal(null)
      setTextModal("")
      setFileMobile(null)
      toggleIsCheck(true)
    }
  }, [])

  React.useEffect(() => toggleIsCheck(fileModal ? true : false), [fileModal])

  const deleteImageHandler = (type) => {
    switch (type) {
      case "modal":
        setFileModal(null)
        break;
      case "desktop":
        setFileDesktop(null)
        break;
      case "mobile":
        setFileMobile(null)
        break;
      default: return;
    }
  }

  const handleSave = () => {
    const payload = {
      id: templateId,
      data: [{
        title: fileDesktop?.key || '',
        fileType,
        description: '',
        url_desktop: fileDesktop?.Location,
        url_mobile: fileMobile?.Location,
        modal_image: fileModal?.Location,
        modal_caption: textModal,
        use_desktop: isCheck
      }],
      index: 0,
      type: 'title',
    };
    dispatch(updateProjectTemplate(payload))
    const arrayData = Array.isArray(form.values.templateGalleries) ? form.values.templateGalleries : []
    arrayData[payload.index] = payload.data[0];
    form.setFieldValue("templateGalleries", arrayData)
    // setSnackOpen(fileDesktop && fileMobile)
    handleClose()

  };

  const handleCheck = () => {
    toggleIsCheck(!isCheck)
  }

  const handleMobileChange = async (event) => {
    const mediaFile = event.target.files[0];
    if (!mediaFile) return;
    const res = await uploadFile(mediaFile)
    if (res) {
      setFileMobile(res.data)
    }
  }
  const handleDesktopChange = async (event) => {
    const mediaFile = event.target.files[0];
    if (!mediaFile) return;
    const res = await uploadFile(mediaFile)
    if (res) {
      setFileType(res.data?.fileType)
      setFileDesktop(res.data)
    }
  }

  const handlePopupChange = async (event) => {
    const mediaFile = event.target.files[0];
    if (!mediaFile) return;
    const res = await uploadFile(mediaFile)
    if (res) {
      setFileModal(res.data)
    }
  }


  const uploadFile = async (mediaFile) => {
    setIsLoading(true)
    const data = new FormData();
    data.append("file", mediaFile);
    const URL = `${process.env.REACT_APP_API_URL}/upload/single`;
    try {
      const res = await axios
        .post(URL, data);
      setIsLoading(false)
      return res.data;
    } catch (error) {
      setIsLoading(false)
      console.log("Upload error", error);
    }

  };
  // const handleSnackClose = () => {
  //   setSnackOpen(false)
  // }
  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <LoadingWithBackDrop message={'Uploading...'} isOpen={isLoading} closeBackDrop={() => { setIsLoading(false) }} />
        {/* <Stack spacing={2} sx={{ width: '100%' }}>
          <Snackbar open={snackOpen} autoHideDuration={3000} onClose={handleSnackClose} anchorOrigin={{ vertical:'top', horizontal:'center' }}>
            <Alert onClose={handleSnackClose} severity="success" sx={{ width: '100%' }}>
              Uploaded successfully!
            </Alert>
          </Snackbar>
        </Stack> */}
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Title
            </Typography>
            <LoadingButton autoFocus color="inherit" loading={isLoading} onClick={handleSave}>
              save
            </LoadingButton>
          </Toolbar>
        </AppBar>

        <List>
          <ListItem>
            <ListItemText
              primary={`Desktop ${fileDesktop ? " - " + fileDesktop.key : ''}`}
              secondary="Supported files .jpeg, .png และ .gif"
            />
            <Stack direction="row" spacing={1}>
              <label htmlFor="desktop-file">
                <Input
                  accept="image/*,video/*"
                  id="desktop-file"
                  type="file"
                  onChange={handleDesktopChange}
                />
                <Button size="small" variant="contained" component="span">
                  Upload
                </Button>
              </label>
              <Button size="small" color="error" variant="contained" component="span" onClick={() => deleteImageHandler("desktop")}>
                Delete
              </Button>
            </Stack>
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary={`Mobile ${fileMobile ? " - " + fileMobile.key : ''}`}
              secondary="Supported files .jpeg, .png และ .gif"
            />
            <Stack direction="row" spacing={1}>
              <label htmlFor="mobile-file">
                <Input
                  accept="image/*,video/*"
                  id="mobile-file"
                  type="file"
                  onChange={handleMobileChange}
                />
                <Button size="small" variant="contained" component="span">
                  Upload
                </Button>
              </label>
              <Button size="small" color="error" variant="contained" component="span" onClick={() => deleteImageHandler("mobile")}>
                Delete
              </Button>
            </Stack>
          </ListItem>
          <Divider />
        </List>
        <List>
          <ListItem>
            <ListItemText
              primary={`Model Image ${fileModal ? " - " + fileModal.key : ''}`}
              secondary="Supported files .jpeg, .png และ .gif"
            />
            <Stack direction="row" spacing={1}>
              <label htmlFor="contained-button-file-popup">
                <Input
                  accept="image/*"
                  id="contained-button-file-popup"
                  type="file"
                  onChange={handlePopupChange}
                />
                <Button size="small" variant="contained" component="span">
                  Upload
                </Button>
              </label>
              <Button size="small" color="error" variant="contained" component="span" onClick={() => deleteImageHandler("modal")}>
                Delete
              </Button>
            </Stack>
          </ListItem>
          <ListItem>
            <ListItemText primary="" secondary="" />
            <TextField value={textModal} fullWidth label="Caption" onChange={(e) => setTextModal(e.target.value)} />
          </ListItem>
          
          <ListItem>
            <FormGroup>
              <FormControlLabel control={<Checkbox checked={isCheck} onChange={handleCheck} />} label="กรณีไม่ได้อัพรูป Model ให้ใช้รูป Desktop แทน" />
            </FormGroup>
          </ListItem>
          
        </List>

      </Dialog>
    </div>
  );
}

import SliderSick from "react-slick";
import styled from 'styled-components'

const Slider = styled(SliderSick)`
  .slick-arrow {
    color: black;
  }
`

const ContainerIcon = styled.div`
  &&& {
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    transition: all 0.25s ease;
  }
  &:hover {
    transform: scale(1.2);
  }
`

const RightArrowContainer = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right:0;
  cursor: pointer;
  opacity: 0;
  /*background-color: rgba(255,255,255,0.4); */
  height: 100%;
  -webkit-transition: all 0.3s ease;
  &:hover {
    opacity: 1;
    background-color: rgba(255,255,255,0.7);
  }
`

const LeftArrowContainer = styled.div`
  position: absolute;
  z-index: 1;
  top: 50%;
  opacity: 0;
  transform: translateY(-50%);
  cursor: pointer;
  background-color: ${props => props.firstTime ? "rgba(255, 255, 255, 0.4)" : "white"};
  left: 0;
  -webkit-transition: all 0.3s ease;
  height: 100%;
  &:hover {
    opacity: 1;
    background-color: ${props => props.firstTime ? "rgba(255, 255, 255, 0.7)" : "white"};
  }
`

const Image = styled.img`
  height: ${props => props.height + "px"}
`

const TextOverlay = styled.div`
 opacity: 0;
 height: 100%; 
 width: 100%;
 background: rgb(138 189 158 / 60%);
 top: 0;
 left: 0; 
 position: absolute; 
 display: flex; 
 align-items: center;
 justify-content: center;
 transition: all 0.45s ease;
 &:hover {
   opacity: 1;
 }
`


export {
  Slider,
  RightArrowContainer,
  LeftArrowContainer,
  ContainerIcon,
  Image,
  TextOverlay
}
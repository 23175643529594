import { ArrowBack } from "@mui/icons-material";
import { CircularProgress, Icon } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ImageSlider, LayoutWithNavbar } from "../../components";
import AdditionalSlider from "../../components/AdditionalSlider";
import { Template1, Template2, Template3 } from "../../components/Templates";

import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import IconButton from "@mui/material/IconButton";

import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { Helmet } from "react-helmet";
import ModalImage from "../../components/ModalWidgets/ModalImage";
import { getProjectById } from "../../services/projectService";
import { TitleContainer } from "../mainStyles";





import AnotherLightbox from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/plugins/captions.css";
import Video from "yet-another-react-lightbox/plugins/video";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";
import useWindowDimensions from "../../hook/Dimension";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function ProjectDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState({});
  const [addsGalleries, setAddsGalleries] = useState([]);
  const [previewSelected, setPreviewSelected] = useState(0);
  const [open, setIsOpen] = useState(false);
  const [dataModal, setDataModal] = useState([]);
  const [dataOthers, setDataOthers] = useState([]);
  const [loading, setLoading] = useState(false);
  const { width } = useWindowDimensions()
  const [isLightBoxOpen, setIsLightboxOpen] = useState(false);
  const [imgIndex, setImgIndex] = useState(0);
  const [images, setImages] = useState([]);
  const lbRef = useRef(null);

  const [openSlides, setOpenSlides] = useState(false);
  const [maxZoomPixelRatio, setMaxZoomPixelRatio] = React.useState(3);
  const [zoomInMultiplier, setZoomInMultiplier] = React.useState(2);
  const [doubleTapDelay, setDoubleTapDelay] = React.useState(300);
  const [doubleClickDelay, setDoubleClickDelay] = React.useState(300);
  const [doubleClickMaxStops, setDoubleClickMaxStops] = React.useState(1);
  const [keyboardMoveDistance, setKeyboardMoveDistance] = React.useState(50);
  const [wheelZoomDistanceFactor, setWheelZoomDistanceFactor] =
    React.useState(100);
  const [pinchZoomDistanceFactor, setPinchZoomDistanceFactor] =
    React.useState(100);
  const [slideIndex, setSlideIndex] = useState(0);
  const [slides, setSlides] = useState([]);
  const [snackOpen, setSnackOpen] = useState(false)

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [id]);

  const getProjectHandler = async (id) => {
    try {
      setLoading(true);
      const response = await getProjectById(id);

      if (response.success) {
        setData(response.data);
        if (
          Array.isArray(response.data?.additional_galleries) &&
          response.data?.additional_galleries.length > 0
        ) {
          const addsImage = response.data?.additional_galleries.map((item) => {
            return {
              id: item._id,
              thumbnail: item.url_desktop,
            };
          });
          setAddsGalleries(addsImage);
        }
      }
      if (
        Array.isArray(response.data.modal_galleries) &&
        response.data.modal_galleries
      ) {
        //NOTE --- Modal Images ----
        const modals = response.data.modal_galleries.map((item) => ({
          src:
            item?.use_desktop !== undefined && !item?.use_desktop
              ? item?.modal_image
              : item?.modal_image || item?.url_desktop,
          caption: item?.modal_caption || "",
          _id: item._id,
        }));
        const imgs = response.data.modal_galleries.map((item) => ({
          url:
            item?.use_desktop !== undefined && !item?.use_desktop
              ? item?.modal_image
              : item?.modal_image || item?.url_desktop,
          caption: item?.modal_caption || "",
          _id: item._id,
        }));
        const slides = response.data.modal_galleries.map((item) => {

          if (item.filetype === "video" || item?.url_desktop?.includes(".mp4")) {
            return {
              type: "video",
              width: width,
              height: width * (9/16),
              poster: item?.url_desktop,
              sources: [
                {
                  src: item?.url_desktop,
                  type: "video/mp4",
                },
              ],
            };
          } else {
            return {
              type: "image",
              src:
                item?.use_desktop !== undefined && !item?.use_desktop
                  ? item?.modal_image
                  : item?.modal_image || item?.url_desktop,
                description: item?.modal_caption || "",
                
            };
          }
        });
        //NOTE --- Modal Images ----
        
        setImages(imgs.filter((m) => m.url !== undefined));
        setDataModal(modals.filter((m) => m.src !== undefined));
        const fSlides = slides.filter((m) => m?.src !== undefined)
        setSlides(fSlides);
        
      }
      if (Array.isArray(response.data_others) && response.data_others) {
        const others = response.data_others.map((item) => ({
          ...item,
          id: item._id,
        }));
        setDataOthers(others);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  
  const previewSelectHandler = (content) => {
    if (!images) return;
    const targetId = content.id || content._id;
    const index = images.findIndex((e) => e._id === targetId);
    setPreviewSelected(index);
    setImgIndex(index);
    setSlideIndex(index)
    if (index !== -1) {
      setTimeout(() => {
        // setIsOpen(true);
        // setIsLightboxOpen(true);
        setOpenSlides(true)
      }, 300);
    }
  };

  useEffect(() => {
    if (id) getProjectHandler(id);
  }, [id]);

  const handleBackPage = () => {
    location?.key ? navigate(-1) : navigate("/");
  };
  //py-10

  const handleClipboard = (e) => {
    //e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    navigator.clipboard.writeText(`${process.env.REACT_APP_SHARE_URL}/project-detail/${id}`)
    setSnackOpen(true)
  }

  
  const handleShareFB = (e) => {
    //e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    console.log("handleShareFB")
    const winWidth = 600;
    const winHeight = 600;
    const title =  data?.title?.th || `BANGKOK BASTARDS`;
    const description = data?.description?.th || `Bangkok Bastards describes the research subjects of CHAT architects. From construction worker houses to forgotten shantytowns, from illegal pop-up markets to street vendor carts, from seedy massage parlors to underground sex motels, these vernacular Bastards are hybrids of questionable origins, scattered though out the city`;
    const url_image = data?.shareimg || `https://bangkokbastards.s3.ap-southeast-1.amazonaws.com/1663604036572-share.jpg`;
    const web = `${process.env.REACT_APP_SHARE_URL}/project-detail/${id}`
    const url = 'https://www.facebook.com/sharer.php?u='+web+'&caption='+title+'&description='+description+'&picture='+url_image;
    window.open(url,'','menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height='+winWidth+',width='+winHeight);
  }

  return (
    <LayoutWithNavbar>
      {loading ? (
        <div
          style={{ height: "95vh" }}
          className="flex flex-1 items-center justify-center"
        >
          <CircularProgress color="inherit" />
        </div>
      ) : (
        <>
          {JSON.stringify(data) !== "{}" && (
            <Helmet>
              <title>BANGKOK BASTARDS {`| ${data?.title?.th}`}</title>
              <meta
                name="keywords"
                content="Bangkok Bastards, Bangkok Bastards, Bangkok Bastard, CHAT architects,สถาปัตยกรรมชั้นเลว,, สถาปัตยกรรมที่ไม่น่าพิสมัย,บ้านคนงานก่อสร้าง,สลัม,แผงลอยเป็นสถาปัตยกรรมชั้นต่ำ,บ้านคนงานก่อสร้าง"
              />
              <meta name="author" content="CHAT architects" />
              <meta
                name="description"
                content={`${data?.description?.th} ,Bangkok Bastards describes the research subjects of CHAT architects. From construction worker houses to forgotten shantytowns, from illegal pop-up markets to street vendor carts, from seedy massage parlors to underground sex motels, these vernacular Bastards are hybrids of questionable origins, scattered though out the city`}
              />
              <meta
                itemprop="name"
                content="สถาปัตยกรรมสารเลว กับมุมมองที่แตกต่างของคุณฉัตรพงษ์ ชื่นฤดีมล&nbsp;แห่ง CHAT Architects"
              />
              <meta
                itemprop="headline"
                content="สถาปัตยกรรมสารเลว กับมุมมองที่แตกต่างของคุณฉัตรพงษ์ ชื่นฤดีมล&nbsp;แห่ง CHAT Architects"
              />
              <meta
                itemprop="image"
                content={
                  `https://bangkokbastards.s3.ap-southeast-1.amazonaws.com/1663604036572-share.jpg`
                }
              />
              <meta
                property="og:url"
                content="https://www.bangkokbastards.com"
              />
              <meta property="og:type" content="article" />
              <meta
                property="og:title"
                content={`BANGKOK BASTARDS | ${data?.title?.th}`}
              />
              <meta
                property="og:description"
                content={`${data?.description?.th} ,Bangkok Bastards describes the research subjects of CHAT architects. From construction worker houses to forgotten shantytowns, from illegal pop-up markets to street vendor carts, from seedy massage parlors to underground sex motels, these vernacular Bastards are hybrids of questionable origins, scattered though out the city`}
              />
              <meta
                property="og:image"
                content={
                 data.shareimg || `https://bangkokbastards.s3.ap-southeast-1.amazonaws.com/1663604036572-share.jpg`
                }
              />
            </Helmet>
          )}

          <div
            className="w-full max-h-full px-4 sm:px-12 md:px-12 lg:px-12 pt-4"
            style={{ backgroundColor: "#f3f3f3" }}
          >
            <ModalImage
              data={images}
              previewSelected={previewSelected}
              open={open}
              setIsOpen={setIsOpen}
            />
            <div
              onClick={handleBackPage}
              className="flex flex-row justify-between items-center mb-2"
            >
              <div className="flex flex-row cursor-pointer">
                <Icon component={ArrowBack} fontSize="small" />
                <p className="font-sarabun text-sm ml-2 font-semibold">Back</p>
              </div>
              <div className="flex flex-row  justify-center align-center">
                <IconButton size="xs" aria-label="คัดลอก" onClick={handleClipboard}><ContentCopyRoundedIcon className="text-2xl"/></IconButton>
                <IconButton  size="xs" aria-label="แชร์" onClick={handleShareFB}><FacebookRoundedIcon /></IconButton>
                
              </div>
            </div>
            {data?.template_id === 1 ? (
              <Template1
                templateGalleries={data?.template_galleries}
                preview
                previewSelectHandler={previewSelectHandler}
              />
            ) : data?.template_id === 2 ? (
              <Template2
                templateGalleries={data?.template_galleries}
                preview
                previewSelectHandler={previewSelectHandler}
              />
            ) : data?.template_id === 3 ? (
              <Template3
                templateGalleries={data?.template_galleries}
                preview
                previewSelectHandler={previewSelectHandler}
              />
            ) : (
              <div></div>
            )}
          </div>
          {Array.isArray(addsGalleries) && addsGalleries.length > 0 && (
            <div className="py-5 md:py-12" style={{ background: "#f3f3f3" }}>
              <TitleContainer>
                <p className="text-secondary">ADDITIONAL IMAGES</p>
              </TitleContainer>
              <AdditionalSlider
                isModal={false}
                disabled
                data={addsGalleries}
                infinite={false}
                slidesToScroll={1}
                onClick={(item) => {
                  previewSelectHandler(item);
                }}
              />
            </div>
          )}
          {Array.isArray(dataOthers) && dataOthers.length > 0 && (
            <div className="py-5 md:py-12" style={{ background: "#f3f3f3" }}>
              <TitleContainer>
                <p style={{ color: "#8a8a8a" }}>OTHER BASTARDS</p>
              </TitleContainer>
              <ImageSlider data={dataOthers} smaller />
            </div>
          )}
          {slides.length > 0 && (
            <AnotherLightbox
              open={openSlides}
              close={() => setOpenSlides(false)}
              index={slideIndex}
              video={{
                playsInline: true,
                autoPlay: true,
                loop: true,
                muted: true,
              }}
              zoom={{
                maxZoomPixelRatio,
                zoomInMultiplier,
                doubleTapDelay,
                doubleClickDelay,
                doubleClickMaxStops,
                keyboardMoveDistance,
                wheelZoomDistanceFactor,
                pinchZoomDistanceFactor,
              }}
              slides={[...slides]}
              plugins={[Zoom, Video, Captions]}
            />
          )}
        </>
      )}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={snackOpen}
        onClose={() => setSnackOpen(false)}
        message="คัดลอกแชร์ URL สำเร็จ"
        key={'clipboard-snack'}
        autoHideDuration={3000}
      >
        <Alert severity="success">คัดลอกแชร์ URL สำเร็จ!</Alert>
      </Snackbar>
    </LayoutWithNavbar>
  );
}



import React, { useCallback, useState } from "react";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import useWindowDimensions from "../../hook/Dimension";
import ModalImage from '../ModalWidgets/ModalImage';
import LeftArrow from "./components/LeftArrow";
import RightArrow from "./components/RightArrow";
import {
  Image,
  Slider,
  TextOverlay,
} from "./styles";

export default function AdditionalSlider({
  data,
  height = 160,
  disabled,
  isAuto = false,
  infinite = true,
  isModal = false,
  slidesToScroll = 2,
  smaller,
  onClick
}) {
  const navigate = useNavigate();
  const [isDragging, setIsDragging] = useState(false);
  const [focusIndex, setFocusIndex] = useState(0);
  const [dataModal, setDataModal] = useState([])
  const [open, setIsOpen] = useState(false);
  const { width } = useWindowDimensions()
  let sliderHeight = width < 640 ? 160: 250
  if(smaller){
    sliderHeight =  width < 640 ? 130: 170
  }
  
  const videoWidth = width < 640 ? 'auto': "100%"
  
  const handleItemClick = useCallback((item, index) => {
    // if (isModal) {
    //   const id = data[index].id
    //   const mapList = data?.map((item) => ({ src: item.thumbnail, caption: "", _id: item.id }))
    //   const selectedIndex = mapList.findIndex((e) => e._id === id)
    //   setFocusIndex(selectedIndex);
    //   setDataModal(mapList)
    //   setIsOpen(true);
    //   return;
    // }
    // console.log(item)
    onClick(data[index])
    // if (!isDragging) {
    //   navigate(`/project-detail/${item.id}`);
    // }
  }, [data, isDragging, isModal]);

  const isVideo = useCallback((url = "") => {
    const videos = ["mp4", "3gp", "ogg"]
    const extension = url?.split(".").pop();
    return videos.includes(extension)
  }, [])

  return (
    <div>
      <Slider
        swipeToSlide
        arrows={!isMobile}
        className="slider variable-width pl-4 sm:pl-12 md:pl-12 lg:pl-12"
        speed={2000}
        slidesToScroll={slidesToScroll}
        adaptiveHeight
        infinite={data.length > 4? true: false}
        cssEase={'ease-out'} 
        variableWidth
        autoplay={isAuto}
        beforeChange={() => setIsDragging(true)}
        afterChange={() => setIsDragging(false)}
        nextArrow={data.length > 4? <RightArrow />: null}
        prevArrow={data.length > 4? <LeftArrow />: null}
        responsive={[
          {
            breakpoint: 640,
            settings: {
              speed: 500
            }
          },
        ]}
      >
        {data.map((item, index) => {
          return (
            <div
              onClick={() => !isDragging && handleItemClick(item, index)}
              className="cursor-pointer"
              key={item}
            >
              <div className="w-auto" style={{ position: "relative" }}>
                {isVideo(item.thumbnail) ?
                  <div style={{ height: sliderHeight, width: videoWidth }}>
                    <video style={{ objectFit: 'cover', height: '100%' }} autoPlay loop muted>
                      <source src={item.thumbnail} type="video/mp4" />
                    </video>
                  </div>
                  :
                  <Image
                    src={item.thumbnail}
                    height={height ? sliderHeight : null}
                    className="object-contain w-auto h-40 md:h-52"
                    style={{ display: "block" }}
                  />}

                {!disabled && (
                  <TextOverlay>
                    <p className="text-white text-xl font-impact text-center">
                      {item.title.en}
                    </p>
                  </TextOverlay>
                )}
              </div>
            </div>
          );
        })}
      </Slider>
      {
        isModal && (
          <ModalImage
            data={dataModal}
            previewSelected={focusIndex}
            open={open}
            setIsOpen={setIsOpen} />
        )
      }
    </div>
  );
}

import React from 'react'
import { LayoutWithNavbar } from '../../components'
import Pic1 from './assets/worker.png'
import Pic2 from './assets/saleng.png'
import Pic3 from './assets/sam_saen_klong.png'
import Pic4 from './assets/makasan.png'
import Pic5 from './assets/dusit.png'
import Pic6 from './assets/scaffolding.png'
import Pic7 from './assets/scaffolding_trans.png'
import Pic8 from './assets/who_document.JPG'
import { Divider, Grid } from '@mui/material'
import { Helmet } from 'react-helmet'

const AboutScreen = () => {
  return (
    <LayoutWithNavbar>
      <Helmet>
        <title>BANGKOK BASTARDS | ABOUT</title>
        <meta name="keywords" content="Bangkok Bastards, Bangkok Bastard, CHAT architects,สถาปัตยกรรมชั้นเลว,, สถาปัตยกรรมที่ไม่น่าพิสมัย,บ้านคนงานก่อสร้าง,สลัม,แผงลอยเป็นสถาปัตยกรรมชั้นต่ำ,บ้านคนงานก่อสร้าง" />
        <meta name="author" content="CHAT architects" />
        <meta name="description" content="Bangkok Bastards describes the research subjects of CHAT architects. From construction worker houses to forgotten shantytowns, from illegal pop-up markets to street vendor carts, from seedy massage parlors to underground sex motels, these vernacular Bastards are hybrids of questionable origins, scattered though out the city." />
        <meta itemprop="name" content="สถาปัตยกรรมสารเลว กับมุมมองที่แตกต่างของคุณฉัตรพงษ์ ชื่นฤดีมล&nbsp;แห่ง CHAT Architects" />
        <meta itemprop="headline" content="สถาปัตยกรรมสารเลว กับมุมมองที่แตกต่างของคุณฉัตรพงษ์ ชื่นฤดีมล&nbsp;แห่ง CHAT Architects" />
        <meta itemprop="image" content="https://bangkokbastards.s3.ap-southeast-1.amazonaws.com/1660290435772-share.jpg" />
        <meta property="og:url" content="https://www.bangkokbastards.com" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="BANGKOK BASTARDS | ABOUT" />
        <meta property="og:description" content="Bangkok Bastards describes the research subjects of CHAT architects. From construction worker houses to forgotten shantytowns, from illegal pop-up markets to street vendor carts, from seedy massage parlors to underground sex motels, these vernacular Bastards are hybrids of questionable origins, scattered though out the city" />
        <meta property="og:image" content="https://bangkokbastards.s3.ap-southeast-1.amazonaws.com/1660290435772-share.jpg" />
      </Helmet>
      <div className='w-full'>
        <h2 className='bg-about p-4 sm:p-12 py-6 font-impact text-textPrimary about-head'>
          BANGKOK BASTARDS
        </h2>
      </div>
      <div className='p-4 sm:p-11'>
        <div className='mb-5'>
          <p className='font-impact text-secondary text-4xl lg:text-6xl mb-1'>Bangkok Bastards Definition</p>
          <p className='font-impact text-secondary text-xl md:text-2xl lg:text-3xl xl:text-4xl'>
            Bangkok:
            <span className='text-lg font-bold  font-sarabun'>
              /bæŋˈkɒk/
            </span>
          </p>
          <p className='font-sarabun font-bold text-base mt-1'>
            Capital of Thailand
          </p>
        </div>
        <div>
          <p className='font-impact text-secondary text-xl md:text-2xl lg:text-3xl xl:text-4xl'>
            Bastards:
            <span className='text-lg font-bold  font-sarabun'>
              /ˈbastərd/
            </span>
          </p>
          <p className='font-sarabun font-bold text-base mt-1'>
            1. Someone or something illegitimate in birth.
          </p>
          <p className='font-sarabun font-bold text-base'>
            2. An unpleasant or despicable person or thing.
          </p>
        </div>
        <Divider style={{ marginTop: 40, marginBottom: 40 }} />
        <div>
          <p className='font-impact text-secondary text-4xl lg:text-6xl'>What are Bangkok Bastards?</p>
          {/* <h5 className='font-sarabun font-bold text-xl mt-1'>
            Bangkok Bastards are homegrown architectural concoctions...created by everyday people...to solve everyday problems...in everyday life.
          </h5> */}
          <div id='content' className='mt-5 mb-5'>
            <p className='font-sarabun text-base mb-5'>
              Bangkok Bastards are homegrown architectural concoctions created by everyday people to solve everyday problems in everyday life. Bastards may include a rundown shack in a neighborhood chumchon (slum), a local street vendor cart, a bastardized shophouse, or a make-shift sidewalk bench.  Bangkokians walk past Bastards every day, but would never consider it as serious design or Architecture.
            </p>
            <p className='font-sarabun text-base mt-5'>
              Like its human namesake, an architectural bastard has no traceable architectural parents/lineage, no cultural history, no design theory that legitimizes it form.  Many view bastards as eyesores to the city, lacking in any serious design pedigree, and unworthy of serious documentation or research.  However, we argue that Bangkok Bastards are the most authentically inventive examples of architecture in Thailand.  They are pure, intuitive, flexible, humorous responses to real problems. Often times, their strategies utilize cheap, salvaged, discarded materials…hacked, re-imagined, and repurposed in entirely new and unexpected ways. They take advantage of existing urban conditions, turn them upside down, to create a new way of living, selling, playing in the city.
            </p>
            <p className='font-sarabun text-base mt-5'>
              Bangkok Bastards are LIVE.  They are not historical case studies frozen in time documented to preserve a past.  Bastards are dynamic, living architectural and spatial strategies that are constantly evolving - yearly, monthly, weekly, daily.  They are architectures that one sees, experiences, and engages immediately when stepping onto the streets, alleys, or shanties of Bangkok.            </p>
          </div>
          <Grid xs={12} spacing={1} container >
            <Grid md={12} lg={6} item>
              <div className='relative'>
                <img className="img-fluid" src={Pic1} alt="construction worker house" />
                <p className='absolute font-sarabun text-white font-bold' style={{ bottom: 10, right: 10 }}>construction worker house</p>
              </div>
            </Grid>
            <Grid md={12} lg={6} item>
              <div className='relative'>
                <img className="img-fluid" src={Pic2} alt="broom closet moto-market" />
                <p className='absolute font-sarabun text-white font-bold' style={{ bottom: 10, right: 10 }}>broom closet moto-market</p>
              </div>
            </Grid>
            <Grid xs={12} item>
              <div className='relative'>
                <img className="img-fluid" src={Pic3} alt="Makasan Flood Canal Community" />
                <p className='absolute font-sarabun text-white font-bold' style={{ bottom: 10, right: 10 }}>Makasan Flood Canal Community</p>
              </div>
            </Grid>
          </Grid>
        </div>
        <Divider style={{ marginTop: 80, marginBottom: 80 }} />
        <div>
          <p className='font-impact text-secondary text-4xl lg:text-6xl'>Why Do We Document Bangkok Bastards?</p>
          <p className='font-sarabun font-bold text-xl mt-1'>
            We record, document, and celebrate Bangkok Bastards because we feel that they are examples of authentic, direct, and solutions in architecture that can be the foundation of an authentic, locally rooted architecture in Thailand.  Bastards provide a source of
          </p>
          <div id='content' className='font-sarabun text-base mt-5 mb-5'>
            <p className='mb-5'>
              In an age where, new, shiny, global architectural designs are posted in a continuous stream on social media, architects in Thailand are inundated with design imagery, that more often than not, is consciously (or unconsciously) adopted as their own language.  For many designers, it is much easier to convince a client that an architectural design is “good” if it has been confirmed on the internet.  The truthfulness, uniqueness, and authenticity of the local is lost.  The problem isn’t only persistent in Bangkok but permeate all throughout Southeast Asia.
            </p>
            <p className='mb-5'>
              Bangkok is Jakarta, is Manila, is Kuala Lumpur, is Hanoi, is Singapore…We drift in a sea of sameness of high end condominiums, world class megamalls, sky-piercing towers… all dressed in global slickness that are turning each ASEAN city into clones of one another.
            </p>
            <p className='mb-5'>
              Bangkok Bastards offer an alternative to contemporary architecture’s obsession with global forms and universal trends through the depiction of an alternative reality.  One that is unique, local, authentic, and express our individual uniqueness.  This reality is documented in curated, detailed, and texture-ful drawings of live local conditions.  Our obsession with details isn’t rooted in illustrative realism, but we feel that realism, more specifically elements from real life, should be considered, critically analyzed, and appropriately utilized in shaping contemporary architecture.  We believe that “architecture” in Bangkok isn’t comprised simply of floors, roofs, and facades…but extend to everyday objects such as potted plants, benches, carts, canopies, and tires floating in the canals.  All of these elements shape space in Southeast Asia in a subtle, complex, layered manner that create blurry overlaps, rather than clear boundaries and rigid spatial delineations.  It is within these blurry overlaps, where building, landscape, sidewalk, street, and canals crossbreed and ‘bastardize’ do we find the real Bangkok.
            </p>
          </div>
          <div className='relative'>
            <img className="img-fluid" src={Pic4} alt="Historic Makasan Flood Canal Community" />
            <p className='absolute font-sarabun text-secondary font-bold' style={{ bottom: 10, right: 10 }}>Historic Makasan Flood Canal Community</p>
          </div>
        </div>
        <Divider style={{ marginTop: 40, marginBottom: 40 }} />
        <div>
          <p className='font-impact text-secondary text-4xl lg:text-6xl'>How Do We Document Bangkok Bastards?</p>
          <p className='font-sarabun font-bold text-xl mt-1'>
            Bangkok Bastard drawings are meticulously drawn, colored, textured, and detailed... in order to illustrate that architecture in Bangkok isn’t limited to buildings, but must necessarily integrate people, animals, plants, and and objects to convey LIFE.
          </p>
          <div id='content' className='font-sarabun text-base mt-5 mb-5'>
            <p className='mb-5'>
              In our documentation of Bangkok Bastards, we utilize the most basic drawings of architecture – plan, section, elevation, and isometric – to tell a story.   And in telling this story, we do not limit ourselves to documenting “buildings” as the only form of architecture.  “Bastard” furniture (a homemade bench), “bastard” landscapes (potted plant gardens), and bastard urbanism (street food vendor streets) are critically considered as architecture.  We stitch together all scales and scopes of “bastard” responses – from architecture, to furniture, to interiors, to landscape, to natural ecologies, to urbanism - in our documentation of hybrid spatial conditions.
            </p>
            <p className='mb-5'>
              We not only document the bastard itself, but the context in which the bastard is situated.  Our drawings are not colorless, but colorful…not clean, but messy.  In Bangkok, we believe that architecture isn’t composed simply of walls, floors, and roofs, but so many other things many don’t consider as “building”.  We therefore, meticulously draw plastic chairs and folding tables, stray dogs, potted plants, trash on the street.  Most importantly, we include people in our drawings, dressed in their local attire, doing real chores in their daily life.  These everyday elements are critical in shaping space, rhythm, and time in our city.
            </p>
          </div>
          <div className='relative'>
            <img className="img-fluid" src={Pic5} alt="Front-door Bridge Bastards, Dusit Neighborhood, Bangkok" />
            <p className='absolute font-sarabun text-white font-bold' style={{ bottom: 10, left: 10 }}>Front-door Bridge Bastards, Dusit Neighborhood, Bangkok</p>
          </div>
        </div>
        <Divider style={{ marginTop: 40, marginBottom: 40 }} />
        <div>
          <p className='font-impact text-secondary text-4xl lg:text-6xl'>How Do We Use Bangkok Bastards?</p>
          <p className='font-sarabun font-bold text-xl mt-1'>
            Bangkok Bastards can be utilized as architectural prototypes and design references for architects, interior designers, and urban planners who wish to truly understand local conditions
            in Thai urbanism.
          </p>
          <div id='content' className='font-sarabun text-base mt-5 mb-5'>
            <p className='mb-5'>
              We draw each Bangkok Bastard as accurately as possible so that each case study can be utilized as an technically-accurate architectural case study, and not perceived simply as a narrative or illustrative device.
            </p>
            <p className='mb-5'>
              It is our goal to present Bangkok Bastards as legitimate examples of architecture.  Therefore, Bangkok Bastards are presented in the most standard architectural projection drawings – plan, section, elevation, isometric.  Although each Bangkok Bastard drawing is rendered in intense detail and color, each subject is accurately drafted in 2D drawings and/or constructed in 3D model in the correct real-world scale and true proportions.  We want designers to not only understand the story behind each bastard, but want to enable them to extract accurate and directly utilizable architectural information from each case study.   This is of critical importance as we want to encourage those who wish to draw design inspiration from Bangkok Bastards to go beyond a surface imagery.  For example, a compact house in a shantytown will be drawn to the exact scale that shows the true dimensions of the interior space and its furniture as this will allow designers to truly understand the accurate dimensions of a useable small space, rather than simply entertaining the superficial idea of a compact architecture.
            </p>
            <p className='mb-5'>
              All drawings – plans, sections, elevations, isometric, details- are drawn to scale and contain true and accurate dimensions.  Therefore, those hoping to utilize the Bastards as design prototype or architectural inspiration can do so properly by truly understanding the correct proportions, construction techniques, accurate texture and color of the original.
            </p>
          </div>

          <img src={Pic6} className="img-fluid" alt="existing construction worker house “scaffolding”" />
          <p className='font-sarabun font-bold text-xl text-center my-5'>
            existing construction worker house “scaffolding”
          </p>
          <img src={Pic7} className="img-fluid" alt="new bastard “scaffolding” transforming existing curtain sex motel into Samsen STREET Hotel" />
          <p className='font-sarabun font-bold text-xl text-center my-5'>
            new bastard “scaffolding” transforming existing curtain sex motel into Samsen STREET Hotel
          </p>
        </div>
        <Divider style={{ marginTop: 40, marginBottom: 40 }} />
        <div className='mt-10'>
          <p className='font-impact text-secondary text-4xl lg:text-6xl mb-10'>Who Documents Bangkok Bastards?</p>
          <p className='font-sarabun text-base mt-5 mb-10'>
            Bangkok-based CHAT Architects and its research branch CHAT Lab, have been photographing, surveying, and documenting Bangkok Bastards since 2012.  The research is proudly self-funded and self-motivated. Over the years, architecture, interior architecture, and urban planning students from universities all throughout Thailand have become the major force behind the research of Bangkok Bastards.  Currently, the documentation of these wonderful subjects has extended to other countries throughout Southeast Asia, with the introduction of S.E.A. Bastards (South East Asia Bastards) Research Initiative by CHAT Architects/CHAT Lab. With the launch of the Bangkok Bastards Website, we encourage everyone to explore your own neighborhood, city, countryside to find your own unique bastard.  Please share your valuable findings with our “Bastard” community!  Our combined efforts will result in an ever-growing “bastard” collective knowledge, laying the foundation for a locally-rooted and culturally meaningful architectural design for Southeast Asia.
          </p>
          <img src={Pic8} className="img-fluid" alt="The Bangkok Bastards Project was initiated by Chatpong “Chat” Chuenrudeemol, Director of CHAT Architects and CHAT Lab in 2012" />
          <p className='font-sarabun font-bold text-xl text-center my-5'>
            The Bangkok Bastards Project was initiated by Chatpong “Chat” Chuenrudeemol, Director of CHAT Architects and CHAT Lab in 2012.
          </p>
         
        </div>
      </div>
    </LayoutWithNavbar>
  )
}

export default AboutScreen


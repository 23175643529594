import { Grid as GridMui } from "@mui/material";
import styled from "styled-components";

const CustomGrid = styled(GridMui)`
  &&.MuiGrid-item {
    @media only screen and (max-width: 900px) {
      padding-top: 8px;
    }
  }
`;


// const Description = styled.attrs(props => ({
//   className: "font-sarabun text-white" + props.className
// }))``
const Description = (props) => {
  return(
    <div className={`font-sarabun text-white txt-descr ${props.className ||''}`}>
      {props.children}
    </div>
  )
};

export { CustomGrid, Description };

import DeleteIcon from '@mui/icons-material/Delete';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import {
  Alert,
  Button,
  Container,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Stack,
  TextField
} from "@mui/material";
import IconButton from '@mui/material/IconButton';
import { styled } from "@mui/material/styles";
import axios from "axios";
import { useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import LoadingWithBackDrop from "../../components/Backdrop/LoadingWithBackDrop";
import {
  Template1,
  Template2,
  Template3
} from "../../components/Templates";
import {
  DOCUMENTER_CHOICE,
  LOCATION_CHOICE,
  TEMPLATE_CHOICE,
  TYPE_CHOICE,
} from "../../constant";

import { range } from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from 'yup';
import { getProjectById, updateProjectById } from "../../services/projectService";
import { TextError } from "./styles";

const Input = styled("input")({
  display: "none",
});
const API_URL = process.env.REACT_APP_API_URL

export default function AddItemScreen() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [additionals, setAdditionals] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [isActive, setIsActive] = useState(false)
  const [status, setStatus] = useState('success');
  const [initialValues, setInitialValues] = useState({
    titleTH: "",
    titleEN: "",
    despTH: "",
    despEN: "",
    location: "",
    province: "",
    documenter: "",
    type: "",
    template: "",
    shareimg: "",
    templateGalleries: [{}, {}, {}, {}, {}, {}, {}, {}, {}],
    additionalGalleries: [],
    thumbnail: "",
  })
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (additionals.length > 0) {
      form.setFieldValue("additionalGalleries", additionals)
    }
  }, [additionals])

  useEffect(() => {
    if (id) {
      getProjectByIdHandler(id)
    }

  }, [id])

  const deleteAdditionalImageHandler = (selectedIndex) => {
    const filterArr = additionals.filter((_, i) => i !== selectedIndex)
    setAdditionals(filterArr)
  }

  const getProjectByIdHandler = async (id) => {
    try {
      const { data } = await getProjectById(id);
      setIsUpdate(true)
      setAdditionals(data.additional_galleries ?? [])
      setInitialValues({
        titleTH: data.title?.th,
        titleEN: data.title?.en,
        despTH: data.description?.th,
        despEN: data.description?.en,
        location: typeof data.location?.main_area === 'string' ? data.location?.main_area.toUpperCase() : "",
        province: data.location?.sub_area,
        documenter: Array.isArray(data.documenters) && data.documenters.length > 0 ? data.documenters[0].name : "",
        type: Array.isArray(data.types) && data.types.length > 0 ? data.types[0].name : "",
        template: data.template_id,
        templateGalleries: setTemplateArray(data.template_galleries),
        additionalGalleries: data.additional_galleries,
        thumbnail: data.thumbnail?.replace(process.env.REACT_APP_MEDIA_URL, process.env.REACT_APP_CDN_URL),
        shareimg: data.shareimg,
      })
    } catch (e) {
      setOpenSnack(true);
      setStatus('error')
    }
  }

  const form = useFormik({
    validateOnBlur: false,
    enableReinitialize: true,
    validateOnChange: false,
    initialValues,
    validationSchema: yup.object().shape({
      titleTH: yup.string().required("Title(th) field is required"),
      titleEN: yup.string().required("Title(en) field is required"),
    }),
    onSubmit: (values) => {
      console.log("xxx ",values)
      const payload = {
        title: {
          en: values.titleEN,
          th: values.titleTH
        },
        banner: "",
        description: {
          en: values.despEN,
          th: values.despTH
        },
        location: {
          main_area: values.location,
          sub_area: values.province,
        },
        documenters: [
          {
            name: values.documenter
          }
        ],
        types: [
          {
            name: values.type
          }
        ],
        shareimg: values.shareimg,
        thumbnail: values.thumbnail?.replace(process.env.REACT_APP_MEDIA_URL, process.env.REACT_APP_CDN_URL),
        template_id: values.template,
        template_galleries: values.templateGalleries,
        additional_galleries: values.additionalGalleries,
        created_by: "SuperAdmin"
      }
      console.log('payload',payload);
      if (isUpdate) {
        updateProjectHandler(payload)
      } else {
        createProjectHandler(payload)
      }
    },
  });

  const updateProjectHandler = async (payload) => {
    payload.active = isActive;
    try {
      if (id) {
        const response = await updateProjectById(id, payload)
        if (response.success) {
          navigate('/bcknd/project-list');
        }
      }
    } catch (e) {
      setOpenSnack(true);
      setStatus('error')
    }
  }

  const setTemplateArray = (data = []) => {
    if (data.length !== 9 && data.length < 9) {
      const fillArr = range(9 - data.length).map((e) => ({}))
      const reData = [...data, ...fillArr]
      return reData
    }
    return data
  }

  const handleUploadFile = useCallback(async (key, event) => {
    const mediaFile = event.target.files[0];
    if (!mediaFile) return;
    const res = await uploadFile(mediaFile)
    if (res) {
      form.setFieldValue(key, res?.data.Location)
    }
  }, [])

  const handleMultipleFileChange = async (event) => {
    setIsLoading(true)
    if (!event.target.files) return;
    const data = new FormData();
    Array.from(event.target.files).forEach(file => {
      data.append("files", file)
    })

    const res = await uploadMultipleFile(data)
    if (res) {
      const jsonFiles = res.data.map(f => ({
        title: f?.key,
        description: '',
        url_desktop: f?.Location,
        url_mobile: f?.Location,
        filetype: 'image'
      }))

      setAdditionals(prev => [...prev, ...jsonFiles])
      setIsLoading(false)
    } else {
      setIsLoading(false)
    }
  }

  const uploadFile = async (mediaFile) => {
    setIsLoading(true)
    const data = new FormData();
    data.append("file", mediaFile);
    const URL = `${process.env.REACT_APP_API_URL}/upload/single`;
    try {
      const res = await axios
        .post(URL, data);
      setIsLoading(false)
      return res.data;
    } catch (error) {
      setIsLoading(false)
    }
  };

  const uploadMultipleFile = async (data) => {
    const URL = `${process.env.REACT_APP_API_URL}/upload/multiple`;
    try {
      const res = await axios
        .post(URL, data);
      return res.data;
    } catch (error) {
      console.log("Upload error", error);
    }
  };

  const createProjectHandler = async (payload) => {
    payload.active = isActive;
    try {
      const response = await axios.post(`${API_URL}/project/create`, payload)
      if (response.status === 201) {
        navigate('/bcknd/project-list');
        console.log('response', response.data)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const signOut = () => {
    localStorage.removeItem("@bkkbstdadm")
    window.location.pathname = '/bcknd/'
  }
  const viewProject = () => window.location.pathname = '/bcknd/project-list'
  return (
    <>
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={openSnack} autoHideDuration={6000} onClose={() => setOpenSnack(false)}>
        {status === "error" ?
          <Alert onClose={() => setOpenSnack(false)} severity="error" sx={{ width: '100%' }}>
            เกิดข้อผิดพลาด
          </Alert> :
          <Alert onClose={() => setOpenSnack(false)} severity="success" sx={{ width: '100%' }}>
            สำเร็จ
          </Alert>}
      </Snackbar>
      <div className="w-full px-4 sm:px-12 md:px-12 lg:px-12 my-10">
        <div className="border-2 border-gray-500 rounded">
          <div className="py-2 text-5xl font-impact text-center">
            <p className="text-secondary text-center">CREATE PROJECT</p>
          </div>
          {/* <div className='text-center py-4 font-sarabun'><Link href="/bcknd/project-list">คลิกเพื่อดูโปรเจ็คทั้งหมด</Link></div> */}
          <div className='flex flex-row justify-center'>
            <div className='text-center py-4 font-sarabun text-md  mx-2'><Button variant="text" onClick={viewProject}>ดูโปรเจ็คทั้งหมด</Button></div>
            <div className='text-center py-4 font-sarabun text-md  mx-2'><Button variant="text" onClick={signOut}>ออกจากระบบ</Button></div>
          </div>
          <Container style={{ overflowX: 'scroll' }} fixed>
            <div className="my-10">
              <p className="text-secondary text-1xl md:text-2xl lg:text-3xl font-impact mb-2">
                TITLE
              </p>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl sx={{ width: "100%" }}>
                    <TextField
                      required
                      id="title-th"
                      label="Title(TH)"
                      size="small"
                      onChange={form.handleChange("titleTH")}
                      value={form.values.titleTH}
                    />
                  </FormControl>
                  {form.errors.titleTH && <TextError>{form.errors.titleTH}</TextError>}
                </Grid>
                <Grid item xs={12}>
                  <FormControl sx={{ width: "100%" }}>
                    <TextField
                      required
                      id="title-en"
                      label="Title(EN)"
                      size="small"
                      onChange={form.handleChange("titleEN")}
                      value={form.values.titleEN}
                    />
                  </FormControl>
                  {form.errors.titleEN && <TextError>{form.errors.titleEN}</TextError>}
                </Grid>
              </Grid>
            </div>
            <div className="my-10">
              <p className="text-secondary text-1xl md:text-2xl lg:text-3xl font-impact mb-2">
                DESCRIPTION
              </p>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl sx={{ width: "100%" }}>
                    <TextField
                      id="desp-th"
                      label="Description(TH)"
                      multiline
                      size="small"
                      rows={2}
                      onChange={form.handleChange("despTH")}
                      value={form.values.despTH}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl sx={{ width: "100%" }}>
                    <TextField
                      id="desp-en"
                      label="Description(EN)"
                      multiline
                      size="small"
                      rows={2}
                      onChange={form.handleChange("despEN")}
                      value={form.values.despEN}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </div>
            <div className="my-10">
              <p className="text-secondary text-1xl md:text-2xl lg:text-3xl font-impact mb-2 uppercase">
                location
              </p>
              <Grid item xs={12} sx={{ marginTop: 2 }}>
                <FormControl size="small" sx={{ width: "100%" }}>
                  <InputLabel id="local-main">Main area</InputLabel>
                  <Select
                    labelId="local-main"
                    label="Main area"
                    id="grouped-native-select"
                    value={form.values.location}
                    displayEmpty
                    onChange={form.handleChange("location")}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    {LOCATION_CHOICE.map((item) => (
                      <MenuItem value={item.key} key={item.key}>
                        {item.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {form.values.location && (
                <Grid item xs={12} sx={{ marginTop: 2 }}>
                  <FormControl size="small" sx={{ width: "100%" }}>
                    <TextField
                      id="sub-area"
                      label="Sub area"
                      size="small"
                      onChange={form.handleChange("province")}
                      value={form.values.province}
                    />
                  </FormControl>
                </Grid>
              )}
            </div>
            <div className="my-10">
              <p className="text-secondary text-1xl md:text-2xl lg:text-3xl font-impact mb-2 uppercase">
                Documenter
              </p>
              <FormControl size="small" sx={{ width: "100%" }}>
                <InputLabel id="local-doc">Documenter</InputLabel>
                <Select
                  labelId="local-doc"
                  label="Documenter"
                  id="grouped-native-select"
                  value={form.values.documenter}
                  onChange={form.handleChange("documenter")}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  {DOCUMENTER_CHOICE.map((item) => (
                    <MenuItem value={item.key} key={item.key}>
                      {item.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="my-10">
              <p className="text-secondary text-1xl md:text-2xl lg:text-3xl font-impact mb-2 uppercase">
                Type
              </p>
              <FormControl size="small" sx={{ width: "100%" }}>
                <InputLabel id="local-type">Type</InputLabel>
                <Select
                  label="Type"
                  labelId="local-type"
                  id="grouped-native-select"
                  value={form.values.type}
                  onChange={form.handleChange("type")}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  {TYPE_CHOICE.map((item) => (
                    <MenuItem value={item.key} key={item.key}>
                      {item.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="my-10">
              <p className="text-secondary text-1xl md:text-2xl lg:text-3xl font-impact mb-2 uppercase">
                Template
              </p>
              <FormControl size="small" sx={{ width: "100%" }}>
                <InputLabel id="local-template">Template</InputLabel>
                <Select
                  label="Template"
                  labelId="local-template"
                  id="grouped-native-select"
                  value={form.values.template}
                  onChange={form.handleChange("template")}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  {TEMPLATE_CHOICE.map((item) => (
                    <MenuItem value={item.key} key={item.key}>
                      {item.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="my-10 items-center justify-center flex flex-col flex-1" style={{ minWidth: 320, overflowX: 'scroll' }}>
              {form.values.template && (
                <p
                  className="font-sarabun text-sm md:text-base"
                >
                  คลิกที่รูปภาพเพื่อทำการเพิ่มไฟล์ หรือข้อความ{" "}
                </p>
              )}
              <div>
                {form.values.template === 1 ? (
                  <Template1
                    isAdmin={true}
                    form={form}
                    templateGalleries={form.values.templateGalleries}
                  />
                ) : form.values.template === 2 ? (
                  <Template2
                    isAdmin={true}
                    form={form}
                    templateGalleries={form.values.templateGalleries}
                  />
                ) : form.values.template === 3 ? (
                  <Template3
                    isAdmin={true}
                    form={form}
                    templateGalleries={form.values.templateGalleries}
                  />
                ) : (
                  <></>
                )}
              </div>

              <Grid className="w-full" item xs={12}>
                <p className="text-secondary mt-6 text-1xl md:text-2xl lg:text-3xl font-impact mb-2 uppercase">
                  Thumbnail
                </p>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <label htmlFor="contained-button-singlefile">
                    <Input accept="image/*,video/*" id="contained-button-singlefile" type="file" onChange={handleUploadFile.bind(this, 'thumbnail')} />
                    <Button variant="contained" component="span">
                      Upload
                    </Button>
                  </label>
                  <label htmlFor="icon-button-singlefile">
                    <Input accept="image/*,image/*,video/*" id="icon-button-singlefile" type="file" onChange={handleUploadFile.bind(this, 'thumbnail')} />
                    <IconButton color="primary" aria-label="upload picture" component="span">
                      <PhotoCamera />
                    </IconButton>
                  </label>
                </Stack>
                <p
                  className="font-sarabun text-sm md:text-base"
                  style={{ marginTop: 10, marginBottom: 20, fontSize: 14 }}
                >
                  {
                    form.values?.thumbnail && <img src={form.values.thumbnail} alt="" style={{ maxWidth: 140 }} />
                  }
                </p>
                <p
                  className="font-sarabun text-sm md:text-base"
                  style={{ marginTop: 20, marginBottom: 20 }}
                >
                  *คลิกเพื่อทำการอัพโหลดรูปภาพสำหรับ Thumbnail {" "}
                </p>
                <Divider />
                <p className="text-secondary mt-6 text-1xl md:text-2xl lg:text-3xl font-impact mb-2 uppercase">
                  Additional Images
                </p>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <label htmlFor="contained-button-file">
                    <Input accept="image/*" id="contained-button-file" multiple type="file" onChange={handleMultipleFileChange} />
                    <Button variant="contained" component="span">
                      Upload
                    </Button>
                  </label>
                  <label htmlFor="icon-button-file">
                    <Input accept="image/*" id="icon-button-file" type="file" onChange={handleMultipleFileChange} />
                    <IconButton color="primary" aria-label="upload picture" component="span">
                      <PhotoCamera />
                    </IconButton>
                  </label>
                </Stack>
                <p
                  className="font-sarabun text-sm md:text-base"
                  style={{ marginTop: 20, marginBottom: 20 }}
                >
                  *คลิกเพื่อทำการเลือกรูปภาพแบบ multiple และสามารถอัพโหลดที่เลือกได้สูงสุด 10 รูป{" "}
                </p>
                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                  {
                    additionals.length > 0 && additionals.map((a, i) => (
                      <div key={i} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', borderWidth: 1, padding: 10, marginRight: 8, marginBottom: 8 }}>
                        <img src={a?.url_desktop || a?.url_mobile} alt="" style={{ maxWidth: 160 }} />
                        <p
                          className="font-sarabun text-sm md:text-base"
                          style={{ marginTop: 10, marginBottom: 10, fontSize: 14 }}
                        >
                          <IconButton aria-label="delete" onClick={() => deleteAdditionalImageHandler(i)} size="small" variant="contained">
                            <DeleteIcon fontSize="medium" color='error' />
                          </IconButton>
                        </p>
                      </div>
                    ))
                  }
                </div>
                <p className="text-secondary mt-6 text-1xl md:text-2xl lg:text-3xl font-impact mb-2 uppercase">
                  Sharing
                </p>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <label htmlFor="contained-button-singlefile-share">
                    <Input accept="image/*,video/*" id="contained-button-singlefile-share" type="file" onChange={handleUploadFile.bind(this, 'shareimg')} />
                    <Button variant="contained" component="span">
                      Upload
                    </Button>
                  </label>
                  <label htmlFor="icon-button-singlefile-share">
                    <Input accept="image/*,image/*,video/*" id="icon-button-singlefile-share" type="file" onChange={handleUploadFile.bind(this, 'shareimg')} />
                    <IconButton color="primary" aria-label="upload picture" component="span">
                      <PhotoCamera />
                    </IconButton>
                  </label>
                </Stack>
                <p
                  className="font-sarabun text-sm md:text-base"
                  style={{ marginTop: 10, marginBottom: 20, fontSize: 14 }}
                >
                  {
                    form.values?.shareimg && <img src={form.values.shareimg} alt="" style={{ maxWidth: 140 }} />
                  }
                </p>
                <p
                  className="font-sarabun text-sm md:text-base"
                  style={{ marginTop: 20, marginBottom: 20 }}
                >
                  *รูปภาพต้องมีขนาดไฟล์ไม่เกิน 8 MB <br />
                  *ขนาดรูปภาพต่ำสุดที่อนุญาตคือ 200 x 200 พิกเซล <br />
                  *ใช้รูปภาพที่มีขนาดอย่างน้อย 1,200 x 630 พิกเซล เพื่อการแสดงผลที่ดีที่สุดบนอุปกรณ์ที่มีความละเอียดสูง <br />
                  *คุณควรใช้รูปภาพที่มีขนาดอย่างน้อยที่สุด 600 x 315 พิกเซลในการแสดงโพสต์บนเพจแบบลิงก์ที่มีรูปภาพขนาดใหญ่ขึ้น
                </p>
              </Grid>
              <Divider />
              <Stack className="my-10" alignItems="center" direction={'row'}>
                <Button onClick={() => { form.handleSubmit(); setIsActive(false) }} className="w-full lg:max-w-md" variant="contained" style={{ minWidth: 150, margin: 5 }}>Save</Button>
                {(form.errors.titleEN || form.errors.titleTH) && <TextError>Please fill the form correctly</TextError>}
                <Button onClick={() => { form.handleSubmit(); setIsActive(true) }} className="w-full lg:max-w-md" variant="contained" color="success" style={{ minWidth: 150, margin: 5 }}>Publish</Button>
                {(form.errors.titleEN || form.errors.titleTH) && <TextError>Please fill the form correctly</TextError>}
              </Stack>
            </div>
          </Container>
        </div>
        <LoadingWithBackDrop message={'Uploading...'} isOpen={isLoading} closeBackDrop={() => { setIsLoading(false) }} />
      </div>
    </>
  );
}

import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { TextareaAutosize } from "@mui/material";

import MuiAlert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import axios from "axios";

import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';


import LoadingWithBackDrop from "../Backdrop/LoadingWithBackDrop";
import { LoadingButton } from "@mui/lab";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Input = styled("input")({
  display: "none",
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function MediaTextDialog({ openMediaText, handleMediaTextClose, contentId, form }) {
  const [value, setValue] = React.useState("media");
  const [fileMobile, setFileMobile] = React.useState(null);
  const [fileDesktop, setFileDesktop] = React.useState(null);
  const [fileModal, setFileModal] = React.useState(null);
  const [fileType, setFileType] = React.useState("image");
  const [textModal, setTextModal] = React.useState("")
  const [title, setTitle] = React.useState("");
  const [titleEn, setTitleEn] = React.useState("");
  const [subTitle, setSubTitle] = React.useState("");
  const [subTitleEn, setSubTitleEn] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [snackOpen, setSnackOpen] = React.useState(false);
  const [isCheck, toggleIsCheck] = React.useState(true);

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const handleCheck = () => {
    toggleIsCheck(!isCheck)
  }


  const deleteImageHandler = (type) => {
    switch (type) {
      case "modal":
        setFileModal(null)
        break;
      case "desktop":
        setFileDesktop(null)
        break;
      case "mobile":
        setFileMobile(null)
        break;
      default: return;
    }
  }
  React.useEffect(() => {
    if (form.values.templateGalleries[contentId]) {
      const data = form.values?.templateGalleries[contentId]
      setFileDesktop(data?.url_desktop ? { key: data.url_desktop || data._id, Location: data?.url_desktop } : null)
      setFileMobile(data?.url_mobile ? { key: data.url_mobile || data._id, Location: data?.url_mobile } : null)
      setFileModal(data?.modal_image ? { key: data.modal_image || data._id, Location: data?.modal_image } : null)
      toggleIsCheck(data?.use_desktop === undefined ? true : data?.use_desktop)
      setFileType(data.filetype)
      setTextModal(data?.modal_caption ?? "")
      if (data.filetype === 'text') {
        setTitle(data.title ?? "")
        setTitleEn(data.title_en ?? "")
        setSubTitle(data.description ?? "")
        setSubTitleEn(data.description_en ?? "")
      } else {
        setTitle("")
        setTitleEn("")
        setSubTitle("")
        setSubTitleEn("")
      }
    } else {
      setFileDesktop(null)
      setFileMobile(null)
      setFileModal(null)
      setSubTitle("")
      setSubTitleEn("")
      setTextModal("")
      setTitle("")
      setTitleEn("")
      toggleIsCheck(true)
    }
  }, [contentId])

  React.useEffect(() => toggleIsCheck(prev => fileModal ? true : prev), [fileModal])

  const handleMobileChange = async (event) => {
    const mediaFile = event.target.files[0];
    if (!mediaFile) return;
    const res = await uploadFile(mediaFile)
    if (res) {
      setFileMobile(res.data)
    }
  }
  const handleDesktopChange = async (event) => {
    const mediaFile = event.target.files[0];
    if (!mediaFile) return;
    const res = await uploadFile(mediaFile)
    if (res) {
      setFileType(res.data?.fileType)
      setFileDesktop(res.data)
    }
  }

  const handlePopupChange = async (event) => {
    const mediaFile = event.target.files[0];
    if (!mediaFile) return;
    const res = await uploadFile(mediaFile)
    if (res) {
      setFileModal(res.data)
    }
  }

  const uploadFile = async (mediaFile) => {
    setIsLoading(true)
    const data = new FormData();
    data.append("file", mediaFile);
    const URL = `${process.env.REACT_APP_API_URL}/upload/single`;
    try {
      const res = await axios
        .post(URL, data);
      setIsLoading(false)
      return res.data;
    } catch (error) {
      setIsLoading(false)
      console.log("Upload error", error);
    }
  };

  const handleMediaTextSave = () => {
    let data = {}
    if (value === "media") {
      data = {
        title: fileDesktop?.key || fileMobile?.key || fileModal?.key,
        title_en: "",
        filetype: fileType,
        isShowModal: true,
        description: '',
        description_en: '',
        url_desktop: fileDesktop?.Location,
        url_mobile: fileMobile?.Location,
        modal_image: fileModal?.Location,
        modal_caption: textModal,
        use_desktop: isCheck
      }
    } else {
      data = {
        title,
        title_en: titleEn,
        filetype: "text",
        description: subTitle,
        description_en: subTitleEn,
        url_desktop: null,
        url_mobile: null,
        modal_image: null,
        modal_caption: "",
      }
    }
    console.log('data', data)
    // setSnackOpen(fileDesktop && fileMobile)
    const arrayData = Array.isArray(form.values.templateGalleries) ? form.values.templateGalleries : []
    arrayData[contentId] = data
    form.setFieldValue("templateGalleries", arrayData)
    handleMediaTextClose()
  }
  // const handleSnackClose = () => {
  //   setSnackOpen(false)
  // }
  return (
    <div>
      <Dialog
        fullScreen
        open={openMediaText}
        onClose={handleMediaTextClose}
        TransitionComponent={Transition}
      >
        <LoadingWithBackDrop message={'Uploading...'} isOpen={isLoading} closeBackDrop={() => { setIsLoading(false) }} />
        {/* <Stack spacing={2} sx={{ width: '100%' }}>
          <Snackbar open={snackOpen} autoHideDuration={3000} onClose={handleSnackClose} anchorOrigin={{ vertical:'top', horizontal:'center' }}>
            <Alert onClose={handleSnackClose} severity="success" sx={{ width: '100%' }}>
              Uploaded successfully!
            </Alert>
          </Snackbar>
        </Stack> */}
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleMediaTextClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Content: {contentId}
            </Typography>
            <LoadingButton autoFocus color="inherit" loading={isLoading} onClick={handleMediaTextSave}>
              save
            </LoadingButton>
          </Toolbar>
        </AppBar>
        <List>
          <ListItem>
            <FormControl>
              <FormLabel id="demo-controlled-radio-buttons-group">
                Type
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={value}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="media"
                  control={<Radio />}
                  label="Media"
                />
                <FormControlLabel
                  value="text"
                  control={<Radio />}
                  label="Text"
                />
              </RadioGroup>
            </FormControl>
          </ListItem>
        </List>
        {/* NOTE Render file or images upload */}
        {value === "media" ? (
          <List>
            <ListItem>
              <ListItemText
                primary={`Desktop ${fileDesktop ? " - " + fileDesktop.key : ''}`}
                secondary="Supported files .jpeg, .png .gif และ video"
              />
              <Stack direction="row" spacing={1}>
                <label htmlFor="contained-button-file-desktop">
                  <Input
                    accept="image/*, video/*"
                    id="contained-button-file-desktop"
                    type="file"
                    onChange={handleDesktopChange}
                  />
                  <Button size="small" variant="contained" component="span">
                    Upload
                  </Button>
                </label>
                <Button size="small" color="error" variant="contained" component="span" onClick={() => deleteImageHandler("desktop")}>
                  Delete
                </Button>
              </Stack>
            </ListItem>
            <ListItem>
              <ListItemText
                primary={`Mobile ${fileMobile ? " - " + fileMobile.key : ''}`}
                secondary="Supported files .jpeg, .png .gif และ video"
              />
              <Stack direction="row" spacing={1}>
                <label htmlFor="contained-button-file-mobile">
                  <Input
                    accept="image/*, video/*"
                    id="contained-button-file-mobile"
                    type="file"
                    onChange={handleMobileChange}
                  />
                  <Button size="small" variant="contained" component="span">
                    Upload
                  </Button>
                </label>
                <Button size="small" color="error" variant="contained" component="span" onClick={() => deleteImageHandler("mobile")}>
                  Delete
                </Button>
              </Stack>
            </ListItem>
          </List>
        ) : (
          <List>
            <List>
              <ListItem>
                <TextField value={title} size="small" fullWidth label="Title(TH)" onChange={(e) => setTitle(e.target.value)} />
              </ListItem>
              <ListItem>
                <TextField value={titleEn} size="small" fullWidth label="Title(EN)" onChange={(e) => setTitleEn(e.target.value)} />
              </ListItem>
            </List>
            <List>
              <ListItem>
                <ListItemText primary="" secondary="" />
                <TextareaAutosize
                  minRows={4}
                  aria-label=""
                  placeholder="Description(TH)"
                  value={subTitle}
                  defaultValue=""
                  onChange={(e) => setSubTitle(e.target.value)}
                  style={{
                    width: "100%",
                    borderWidth: 1,
                    padding: 20,
                    borderColor: "#888",
                  }}
                />
              </ListItem>
              <ListItem>
                <ListItemText primary="" secondary="" />
                <TextareaAutosize
                  minRows={4}
                  aria-label=""
                  placeholder="Description(EN)"
                  value={subTitleEn}
                  defaultValue=""
                  onChange={(e) => setSubTitleEn(e.target.value)}
                  style={{
                    width: "100%",
                    borderWidth: 1,
                    padding: 20,
                    borderColor: "#888",
                  }}
                />
              </ListItem>
            </List>

          </List>
        )}
        <List>
          <ListItem>
            <ListItemText
              primary={`Model ${fileModal ? " - " + fileModal.key : ''}`}
              secondary="Supported files .jpeg, .png .gif และ .mp4"
            />
            <Stack direction="row" spacing={1}>
              <label htmlFor="contained-button-file-popup">
                <Input
                  accept="image/*, video/mp4"
                  id="contained-button-file-popup"
                  type="file"
                  onChange={handlePopupChange}
                />
                <Button size="small" variant="contained" component="span">
                  Upload
                </Button>
              </label>
              <Button size="small" color="error" variant="contained" component="span" onClick={() => deleteImageHandler("modal")}>
                Delete
              </Button>
            </Stack>
          </ListItem>
          <ListItem>
            <ListItemText primary="" secondary="" />
            <TextField value={textModal} fullWidth label="Caption" onChange={(e) => setTextModal(e.target.value)} />
          </ListItem>
          <ListItem>
            <FormGroup>
              <FormControlLabel control={<Checkbox checked={isCheck} onChange={handleCheck} />} label="กรณีไม่ได้อัพรูป Model ให้ใช้รูป Desktop แทน" />
            </FormGroup>
          </ListItem>
        </List>
      </Dialog>
    </div>
  );
}

import React from "react";
import { Description } from "../TemplateWidgets/styles";
import useWindowDimensions from "../../hook/Dimension";
import zIndex from "@mui/material/styles/zIndex";

import { useDispatch } from "react-redux";
import { setViewMoreItem } from "../../redux/projectSlice";
import { textAlign } from "@mui/system";
import { useRef, useState, useCallback } from "react";
import { useEffect } from "react";

const BOX_MARGIN = 4;
const INNER_MARGIN = 14;
const MB_SPACE = "5px 0";

export const Title = ({ isAdmin = false, width, height, url, mbUrl }) => {
  const isMobileSize = useWindowDimensions().width < 640;
  const isResponsive = !isAdmin && isMobileSize;

  if (isResponsive) {
    return (
      <img
        src={mbUrl}
        style={{ width: "100%", height: "auto", margin: MB_SPACE }}
        alt="Project Title"
      />
    );
  } else {
    return (
      <div
        style={{
          width: width - BOX_MARGIN * 2,
          height: height - BOX_MARGIN * 2,
          margin: BOX_MARGIN,
          background: `url(${url}) center center/cover`,
        }}
      ></div>
    );
  }
};
export const TUniversalBoxTitle = ({ isAdmin = false, url, mbUrl }) => {
  const isMobileSize = useWindowDimensions().width < 640;
  const isResponsive = !isAdmin && isMobileSize;
  const isVideo = React.useCallback((url = "") => {
    const videos = ["mp4", "3gp", "ogg"];
    const extension = url?.split(".").pop();
    return videos.includes(extension);
  }, []);
  if (isVideo(url)) {
    return (
      <video
        autoPlay
        muted
        loop
        style={{
          position: isResponsive ? "relative" : "absolute",
          width: "100%",
          height: isResponsive ? "auto" : "100%",
          left: isResponsive ? 0 : "50%",
          top: isResponsive ? 0 : "50%",
          objectFit: "cover",
          transform: isResponsive ? `translate(0, 0)` : `translate(-50%, -50%)`,
        }}
      >
        <source src={url} type="video/mp4" />
      </video>
    );
  } else {
    return (
      <img
        src={isResponsive ? mbUrl : url}
        style={{
          width: "100%",
          height: isResponsive ? "auto" : "100%",
          position: isResponsive ? "relative" : "absolute",
          left: 0,
          top: 0,
          zIndex: 9,
        }}
        alt="Project Title"
      />
    );
  }
  // if (isResponsive) {
  //   return (
  //     <img
  //       src={mbUrl}
  //       style={{ width: "100%", height: "auto" }}
  //       alt="Project Title"
  //     />
  //   );
  // } else {
  //   return (
  //     <img
  //       src={url}
  //       style={{
  //         width: "100%",
  //         height: "100%",
  //         position: "absolute",
  //         left: 0,
  //         top: 0,
  //         zIndex: 9,
  //       }}
  //       alt="Project Title"
  //     />
  //   );
  // }
};

export const TUniversalBox = ({
  isAdmin = false,
  mbUrl,
  flex,
  type,
  url,
  margin = BOX_MARGIN,
  title,
  description,
  className = "",
  bgColor = "#2c2c2c",
  onClick,
  useDesktop = true
}) => {
  const isMobileSize = useWindowDimensions().width < 640;
  const isResponsive = !isAdmin && isMobileSize;
  const dispatch = useDispatch();
  const [isReadMore, setIsReadMore] = useState(false)
  // const contentRef = useRef(null)
  // const descRef = useRef(null)
  // const titleRef = useRef(null)

  const useStateRef = (processNode) => {
    const [node, setNode] = useState(null);
    const setRef = useCallback(newNode => {
      setNode(processNode(newNode));
    }, [processNode]);
    return [node, setRef];
  }
  const [contentHeight, contentRef] = useStateRef(node => (node?.clientHeight || 0));
  const [descHeight, descRef] = useStateRef(node => (node?.clientHeight || 0));
  const [titleHeight, titleRef] = useStateRef(node => (node?.clientHeight || 0));

  
  
  useEffect(() => {
    setIsReadMore((descHeight||titleHeight) + 110 > contentHeight)
  },[contentHeight, descHeight, titleHeight])

  switch (type) {
    case "image":
      if (isResponsive) {
        return (
          <img
            onClick={onClick}
            style={{ cursor: useDesktop ? "pointer" : "auto", width: "100%", height: "auto" }}
            alt={title}
            src={mbUrl}
          />
        );
      } else {
        return (
          // <img
          //   onClick={onClick}
          //   style={{
          //     width: "100%",
          //     height: "100%",
          //     position: "absolute",
          //     left: 0,
          //     top: 0,
          //     zIndex: 9,
          //   }}
          //   src={url}
          //   alt={title}
          // />
          <div
            onClick={onClick}
            style={{
              cursor: useDesktop ? "pointer" : "auto",
              width: "100%",
              height: "100%",
              position: "absolute",
              left: 0,
              top: 0,
              zIndex: 9,
              background: `url(${url}) center center/cover`,
            }}
          ></div>
        );
      }

    case "text":
      return (
        <>
          <div
            onClick={onClick}
            ref={contentRef}
            style={{
              position: isResponsive ? "relative" : "absolute",
              top: 0,
              left: 0,
              background: isAdmin ? bgColor : "#2c2c2c",
              width: "100%",
              height: isResponsive ? "auto" : "100%",
              overflow: "hidden",
              padding: 10,
            }}
          >
            <p ref={titleRef} className="font-impact text-white txt-title">{title}</p>
            <div ref={descRef}>
              <Description  className={`${className}`}>{description}</Description>
            </div>
          </div>
          {
            isReadMore
            &&
            <div
              onClick={() =>
                dispatch(
                  setViewMoreItem({ title, description, openStatus: true })
                )
              }
              style={{
                fontSize: 12,
                position: "absolute",
                bottom: 0,
                width: "100%",
                left: 0,
                padding: "10px",
                cursor: "pointer",
                background: isAdmin ? bgColor : "#2c2c2c",
                boxShadow: `{isAdmin ? bgColor : "#2c2c2c"} 0px -4px 0px 0px`,
                textAlign:'right'
              }}
            >
              <p className="font-sarabun" style={{textDecoration: 'none', lineHeight: 1, color: isAdmin ? "#fefefe" : "#a7a5a5", padding: "6px 10px"}}>READ MORE</p>
            </div>
          }
         
        </>
      );
    case "video":
      return (
        <div
          onClick={onClick}
          style={{
            position: isResponsive ? "relative" : "absolute",
            top: 0,
            left: 0,
            height: isResponsive ? "auto" : "100%",
            width: "100%",
            cursor: useDesktop ? "pointer" : "auto",
            flex,
            background: `url(${url}) center center/cover`,
          }}
        >
          {isResponsive ? (
            <video
              autoPlay
              muted
              loop
              style={{
                width: "100%",
                height: "auto",
                objectFit: "cover",
                background: "#2c2c2c",
              }}
            >
              <source src={url} type="video/mp4" />
            </video>
          ) : (
            <video
              autoPlay
              muted
              loop
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                left: "50%",
                top: "50%",
                objectFit: "cover",
                transform: `translate(-50%, -50%)`,
                background: "#2c2c2c",
              }}
            >
              <source src={url} type="video/mp4" />
            </video>
          )}
        </div>
      );
    default:
      return (
        <div
          onClick={onClick}
          style={{
            flex,
            background: isAdmin ? bgColor : "#2c2c2c",
            cursor: "pointer",
            position: isResponsive ? "relative" : "absolute",
            top: 0,
            left: 0,
            height: isResponsive ? "auto" : "100%",
            width: "100%",
          }}
        ></div>
      );
  }
};

export const TMultiBox = ({
  isAdmin = false,
  mbUrl,
  flex,
  type,
  url,
  margin = BOX_MARGIN,
  title,
  description,
  className = "",
  bgColor = "#2c2c2c",
  onClick,
}) => {
  const isMobileSize = useWindowDimensions().width < 640;
  const isResponsive = !isAdmin && isMobileSize;
  switch (type) {
    case "image":
      if (isResponsive) {
        return (
          <img
            onClick={onClick}
            style={{
              cursor: "pointer",
              width: "100%",
              height: "auto",
              margin: MB_SPACE,
            }}
            alt={title}
            src={mbUrl}
          />
        );
      } else {
        return (
          <div
            onClick={onClick}
            style={{
              cursor: "pointer",
              flex,
              margin,
              background: `url(${url}) center center/cover`,
            }}
          ></div>
        );
      }

    case "text":
      return (
        <div
          onClick={onClick}
          style={{
            flex,
            margin: isResponsive ? MB_SPACE : margin,
            background: isAdmin ? bgColor : "#2c2c2c",
          }}
        >
          <div style={{ margin: INNER_MARGIN }}>
            <p className="font-impact text-white txt-title">{title}</p>
            <Description className={`${className}`}>{description}</Description>
          </div>
        </div>
      );
    case "video":
      return (
        <div
          onClick={onClick}
          style={{
            position: "relative",
            cursor: "pointer",
            flex,
            width: "100%",
            background: `url(${url}) center center/cover`,
          }}
        >
          {isResponsive ? (
            <video
              autoPlay
              muted
              loop
              style={{
                width: "100%",
                height: "auto",
                objectFit: "cover",
              }}
            >
              <source src={url} type="video/mp4" />
            </video>
          ) : (
            <video
              autoPlay
              muted
              loop
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                left: "50%",
                top: "50%",
                objectFit: "cover",
                transform: `translate(-50%, -50%)`,
              }}
            >
              <source src={url} type="video/mp4" />
            </video>
          )}
        </div>
      );
    default:
      return (
        <div
          onClick={onClick}
          style={{
            flex,
            margin,
            background: isAdmin ? bgColor : "#2c2c2c",
            cursor: "pointer",
          }}
        ></div>
      );
  }
};

export const TBox = ({ flex, type, url, margin = BOX_MARGIN, onClick }) => {
  return (
    <div
      onClick={onClick}
      style={{
        cursor: "pointer",
        flex,
        margin,
        background: `url(${url}) center center/cover`,
      }}
    ></div>
  );
};

export const TTextBox = ({
  flex,
  title,
  description,
  className = "",
  margin = BOX_MARGIN,
  onClick,
  bgColor = "#2c2c2c",
}) => {
  return (
    <div
      onClick={onClick}
      style={{ flex, margin: margin, background: bgColor }}
    >
      <div style={{ margin: INNER_MARGIN }}>
        <p
          className="font-impact text-white header-size"
          style={{ fontSize: 24 }}
        >
          {title}
        </p>
        <Description className={`${className}`}>{description}</Description>
      </div>
    </div>
  );
};
export const TVideoBox = ({
  flex,
  type,
  url,
  margin = BOX_MARGIN,
  onClick,
}) => {
  return (
    <div
      onClick={onClick}
      style={{
        position: "relative",
        cursor: "pointer",
        flex,
        margin,
        background: `url(${url}) center center/cover`,
      }}
    >
      <video
        autoPlay
        muted
        loop
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          left: "50%",
          top: "50%",
          objectFit: "cover",
          transform: `translate(-50%, -50%)`,
        }}
      >
        <source src={url} type="video/mp4" />
      </video>
    </div>
  );
};

import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useFormik } from "formik";
import {
  DOCUMENTER_CHOICE,
  LOCATION_CHOICE,
  PROVICE_CHOICE,
  TEMPLATE_CHOICE,
  TEMPLATE_ONE_INPUT,
  TEMPLATE_TWO_INPUT,
  TEMPLATE_THREE_INPUT,
  TYPE_CHOICE,
} from "../../constant";
import {
  
  Grid,
  TextField,
  Select,
  MenuItem,
  Button,
  Stack,
  Container,
  Divider,
  Link,
  InputLabel,
  Alert,
  Snackbar
} from "@mui/material";

import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import LoadingWithBackDrop from "../../components/Backdrop/LoadingWithBackDrop";
import axios from "axios";

import * as yup from 'yup'
import { TextError } from "./styles";
import { useDispatch } from "react-redux";
import { ProjectServices } from "../../services";
import { useNavigate, useParams } from "react-router-dom";
import { getProjectById, updateProjectById } from "../../services/projectService";
import { range } from "lodash";

const Input = styled("input")({
  display: "none",
});
const API_URL = process.env.REACT_APP_API_URL

export default function AddHomeScreen() {
  const navigate = useNavigate();

  const [imgUrl, setImageUrl] = useState(null);
 
  
  const [openSnack, setOpenSnack] = useState(false);
  const [status, setStatus] = useState('success');
  const [initialValues, setInitialValues] = useState({
    titleTH: "",
    titleEN: "",
    despTH: "",
    despEN: "",
    location: "",
    province: "",
    documenter: "",
    type: "",
    template: "",
    templateGalleries: [{}, {}, {}, {}, {}, {}, {}, {}, {}],
    additionalGalleries: [],
    thumbnail: "",
  })
  const [isLoading, setIsLoading] = useState(false)

  

  

  const updateBannerHandler = async (payload) => {
    // try {
    //   if (id) {
    //     const response = await updateProjectById(id, payload)
    //     if (response.success) {
    //       navigate('/bcknd/project-list');
    //     }
    //   }
    // } catch (e) {
    //   setOpenSnack(true);
    //   setStatus('error')
    // }
  }

  

  const handleUploadFile = async (event) => {
    const mediaFile = event.target.files[0];
    if (!mediaFile) return;
    const res = await uploadFile(mediaFile)
    if (res) {
      setImageUrl(res?.data?.Location)
    }
  }

  

  const uploadFile = async (mediaFile) => {
    setIsLoading(true)
    const data = new FormData();
    data.append("file", mediaFile);
    const URL = `${process.env.REACT_APP_API_URL}/upload/single`;
    try {
      const res = await axios
        .post(URL, data);
      setIsLoading(false)
      
      return res.data;
    } catch (error) {
      setIsLoading(false)
    }
  };
 
  
  return (
    <>
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={openSnack} autoHideDuration={6000} onClose={() => setOpenSnack(false)}>
        {status === "error" ?
          <Alert onClose={() => setOpenSnack(false)} severity="error" sx={{ width: '100%' }}>
            เกิดข้อผิดพลาด
          </Alert> :
          <Alert onClose={() => setOpenSnack(false)} severity="success" sx={{ width: '100%' }}>
            สำเร็จ
          </Alert>}
      </Snackbar>
      <div className="w-full px-4 sm:px-12 md:px-12 lg:px-12 my-10">
        <div className="border-2 border-gray-500 ma-5 px-5 rounded">
          <div className="py-2 text-5xl font-impact text-center">
            <p className="text-secondary text-center">HOME BANNER</p>
          </div>
          <div className='text-center py-4 font-sarabun'><Link href="/bcknd/project-list">คลิกเพื่อดูโปรเจ็คทั้งหมด</Link></div>
          <Container fixed>
           
       
            <div className="my-10 items-center justify-center flex flex-col flex-1">
      
              <Grid className="w-full" item xs={12}>
                <p className="text-secondary mt-6 text-1xl md:text-2xl lg:text-3xl font-impact mb-2 uppercase">
                  HOME BANNER
                </p>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <label htmlFor="contained-button-singlefile">
                    <Input accept="image/*" id="contained-button-singlefile" type="file" onChange={handleUploadFile} />
                    <Button variant="contained" component="span">
                      Upload
                    </Button>
                  </label>
                  <label htmlFor="icon-button-singlefile">
                    <Input accept="image/*" id="icon-button-singlefile" type="file" onChange={handleUploadFile} />
                    <IconButton color="primary" aria-label="upload picture" component="span">
                      <PhotoCamera />
                    </IconButton>
                  </label>
                </Stack>
                <p
                  className="font-sarabun text-sm md:text-base"
                  style={{ marginTop: 10, marginBottom: 20, fontSize: 14 }}
                >
                  {imgUrl||''}
                </p>
                <p
                  className="font-sarabun text-sm md:text-base"
                  style={{ marginTop: 20, marginBottom: 20 }}
                >
                  *คลิกเพื่อทำการอัพโหลดรูปภาพสำหรับ แสดงหน้าหลัก {" "}
                </p>
                <Divider />
               
                
              </Grid>
              <Divider />
              <Stack className="my-10" alignItems="center" >
                <Button onClick={updateBannerHandler} className="w-full lg:max-w-md" variant="contained" style={{ minWidth: 300 }}>SAVE</Button>
                
              </Stack>
            </div>
          </Container>
        </div>
        <LoadingWithBackDrop message={'Uploading...'} isOpen={isLoading} closeBackDrop={() => { setIsLoading(false) }} />
      </div>
    </>
  );
}

import { configureStore, combineReducers } from '@reduxjs/toolkit'
import thunk from 'redux-thunk'
import projectSlice from './redux/projectSlice'
import appSlice from './redux/appSlice'

const reducers = combineReducers({
  projects: projectSlice,
  app: appSlice
});
export const store = configureStore({
  reducer: reducers,
  middleware: getDefaultMiddleware => getDefaultMiddleware([thunk])
})

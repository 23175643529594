import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector, useDispatch } from 'react-redux'
import { getViewMoreState, setViewMoreItem } from '../../redux/projectSlice';

import IconButton from "@mui/material/IconButton";

import CloseIcon from "@mui/icons-material/Close";

export default function ViewMoreDialog() {
  const { title, description, openStatus } = useSelector(getViewMoreState)
  const dispatch = useDispatch()


  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');



  const handleClose = () => {
    dispatch(setViewMoreItem({ title: '', description: '', openStatus: false }));
  };


  return (
    <React.Fragment>
      <Dialog
        sx={{ backgroundColor: 'rgba(0,0,0,0.89)' }}
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
            color: '#fff'
          },
        }}
        fullWidth={fullWidth}
        maxWidth={'md'}
        open={openStatus}
        onClose={handleClose}
      >
        <DialogTitle>
          <div className='flex flex-row' style={{ justifyContent: 'space-between', alignItems: 'center', padding: 10 }}>
            <p className='font-impact'>
              {title}
            </p>
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
              style={{ padding: '0' }}
            >
              <CloseIcon />
            </IconButton>
          </div>

        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{ color: '#fff', maxHeight: 250, overflow: 'scroll', padding: '0 10px 10px' }} className="dcontent">
            <span className='font-sarabun mt-4'>
              {description}
            </span>

          </DialogContentText>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  mb_container: {
    "& .MuiPaper-root": {
      left: "0 !important",
      top: "65px !important",
      width: "100%",
      maxWidth: "100%",
      boxShadow:
        "0px 4px 2px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)",
      borderRadius: 0,
      "& .MuiList-root": {
        paddingTop: 0,
        paddingBottom: 0,
      },
      [theme.breakpoints?.only('xs')]: {
        top: "57px !important",
      },
    },
  },
  lng_container: {
    [theme.breakpoints?.only('xs')]: {
      position: "absolute",
      width: '100%',
      right: 18,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      alignItem: 'flex-end'
    },
  },
  head_nav: {
    [theme.breakpoints?.only('xs')]: {
      padding: "0 !important",
    },
  },
  foot_nav: {
    [theme.breakpoints?.only('xs')]: {
      padding: "15px !important",
    },
  },
  start_icon: {
    marginRight: "2px" 
  },
  end_icon: {
    marginLeft: "2px" 
  }
}));

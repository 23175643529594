import * as React from "react";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Link from "@mui/material/Link";
import MenuIcon from "@mui/icons-material/Menu";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

import Button from "@mui/material/Button";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LanguageIcon from "@mui/icons-material/Language";
import { useStyles } from "./navStyle";
import { ListItemIcon } from "@mui/material";
import AppMusic from '../../assets/audio/sound.mp3'
import useWindowDimensions from "../../hook/Dimension";
import { useSelector, useDispatch } from "react-redux";
import { getSound, toggleSoundPlay } from "../../redux/appSlice";
import { RouterContext } from "../../App";
const bastards = [
  { name: "By LOCATION", href: "/project-list/location" },
  { name: "By DOCUMENTER", href: "/project-list/documenter" },
  { name: "By TYPE", href: "/project-list/type" },
];

// const languages = ["TH", "EN"];
const languages = ["EN"]

export default function PrimarySearchAppBar() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [anchorElLanguage, setAnchorElLanguage] = React.useState(null);
  const [anchorElBastards, setAnchorElBastards] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const [open, setOpen] = React.useState({
    bsMenu: false,
    lngMenu: false,
  });
  const dispatch = useDispatch()
  const [isPlay, setIsPlay] = React.useState(true);
  const isSoundOn  = useSelector(getSound)
  const {audioPlay, setAudio } = React.useContext(RouterContext)
  

  

  const audioRef = React.useRef()

  React.useEffect(() => {
    console.log('Music 🔊 ',isPlay)
    if(isPlay){
      // audioRef && audioRef.current.play()
      // document.getElementById("audio-sound").play()
    }else{
      // audioRef && audioRef.current.pause()
      // document.getElementById("audio-sound").pause()
    }

  },[isPlay])
  

  // const handleClick = () => {
  //   setOpen(!open);
  // };
  // const handleLangClick = () => {
  //   setLangOpen(!open);
  // };
  
  
  

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOpenLanguageMenu = (event) => {
    setAnchorElLanguage(event.currentTarget);
  };
  const handleCloseLanguageMenu = () => {
    setAnchorElLanguage(null);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElBastards(event.currentTarget);
  };
  const handleCloseBastardsMenu = () => {
    setAnchorElBastards(null);
  };
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const classes = useStyles();

  const mobileMenuId = "primary-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 0,
        horizontal: 100,
      }}
      id={mobileMenuId}
      keepMounted
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
      className={classes.mb_container}
      sx={{ display: { xs: "block", md: "none" } }}
    >
      <List
        sx={{ width: "100%", bgcolor: "background.paper" }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        <ListItemButton divider component="a" href="/">
          <ListItemText primary="HOME" style={{ fontWeight: 400 }} />
        </ListItemButton>
        <ListItemButton
          onClick={() => setOpen({ ...open, bsMenu: !open.bsMenu })}
          divider
        >
          <ListItemText primary="BASTARDS" />
          {open.bsMenu ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={open.bsMenu} timeout="auto" unmountOnExit>
          {bastards.map(({ name, href }) => (
            <ListItemButton key={name} sx={{ pl: 4 }} divider component="a" href={href}>
              <ListItemText primary={name} />
            </ListItemButton>
          ))}
        </Collapse>
        <ListItemButton divider component="a" href="/about">
          <ListItemText primary="ABOUT US"  />
        </ListItemButton>
        <ListItemButton
          divider
          onClick={() => setOpen({ ...open, lngMenu: !open.lngMenu })}
        >
          <ListItemIcon>
            <LanguageIcon />
          </ListItemIcon>
          <ListItemText sx={{ ml: -3 }} primary="LANGUAGE" />
          {open.lngMenu ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={open.lngMenu} timeout="auto" unmountOnExit>
          {languages.map((ln) => (
            <ListItemButton key={ln} sx={{ pl: 4 }} divider>
              <ListItemText primary={ln} />
            </ListItemButton>
          ))}
        </Collapse>
      </List>
    </Menu>
  );
  
  const { height, width } = useWindowDimensions();
  return (
    <Box sx={{ flexGrow: 1,height: width > 600? 68.5: 57 }}>  
    {/* <audio autoPlay loop ref={audioRef} id="audio-sound">
        <source src={AppMusic} type="audio/mp3" />
    </audio> */}
      <AppBar className={classes.head_nav} position="fixed" sx={{ zIndex: 1400, bgcolor: "#fff", paddingLeft: 3, paddingRight: 2, boxShadow: 0, borderBottom:'0.5px solid #2C2C2C' }} elevation={1} >
        <Toolbar>
          <Link href="/" color="inherit" underline="none">
            <Typography
              variant="h5"
              noWrap
              component="div"
              color="#000"
              className=" font-impact text-lg md:text-2xl my-4"
            >
              <span className="font-impact text-lg md:text-2xl my-4 txt--default" style={{letterSpacing: 1}}>
                BANGKOK BASTARDS
              </span>
            </Typography>
          </Link>

          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <Link href="/" color="inherit" underline="none">
              <Button sx={{ my: 2, color: "#000", display: "block", fontWeight: 400 }}>
                Home
              </Button>
            </Link>
            <Button
              sx={{ my: 2, color: "#000", display: "block", fontWeight: 400  }}
              onClick={handleOpenUserMenu}
            >
              Bastards
            </Button>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElBastards}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElBastards)}
              onClose={handleCloseBastardsMenu}
            >
              {bastards.map(({ name, href }) => (
                <Link key={name} color="inherit" underline="none" href={href}>
                  <MenuItem
                    onClick={handleCloseBastardsMenu}
                    divider={true}
                    style={{ minWidth: 200 }}
                  >
                    <Typography textAlign="center">{name}</Typography>
                  </MenuItem>
                </Link>
              ))}
            </Menu>
            <Link href="/about" color="inherit" underline="none">
              <Button sx={{ my: 2, color: "#000", display: "flex" ,fontWeight: 400 }}>
                About Us
              </Button>
            </Link>

            <Button
              sx={{ my: 2, color: "#000", display: "flex", fontWeight: 400 }}
              direction="row"
              startIcon={<SignalCellularAltIcon style={{marginRight: -6}} />}
              // onClick={() =>  {dispatch(toggleSoundPlay(!isSoundOn)); setIsPlay(!isPlay)}}
              onClick={() => setAudio(!audioPlay)}
            >
              
              {audioPlay? 'ON': 'OFF'}
            </Button>
            <Button
              onClick={handleOpenLanguageMenu}
              sx={{ my: 2, color: "#000", display: "flex" , fontWeight: 400 }}
              direction="row"
              startIcon={<LanguageIcon style={{marginRight: -6}} />}
              endIcon={<KeyboardArrowDownIcon style={{marginLeft: -6}} />}
            >
              Language
            </Button>
            <Menu
              sx={{ mt: "45px" }}
              id="lang-appbar"
              anchorEl={anchorElLanguage}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElLanguage)}
              onClose={handleCloseLanguageMenu}
            >
              {languages.map((el) => (
                <MenuItem
                  key={el}
                  onClick={handleCloseLanguageMenu}
                  divider={true}
                  style={{ minWidth: 120 }}
                >
                  <Typography textAlign="center">{el}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          {/* Mobile burgur menu */}
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <Button
              sx={{ color: "#000", display: "flex" }}
              direction="row"
              startIcon={<SignalCellularAltIcon />}
              // onClick={() => setIsPlay(prev=> !prev)}
              onClick={() => setAudio(!audioPlay)}
            >
              
              {audioPlay? 'On': 'OFF'}
            </Button>
            <IconButton
              className="app-menu"
              onClick={handleMobileMenuOpen}
              aria-label="open menu"
              component="span"
              sx={{ color: "#000", display: "flex",  '&.Mui-selected': {
                outline: 'none',
            }}}
             
            >
              <MenuIcon />
            </IconButton>
          </Box>
          {/* Mobile burgur menu */}
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
    </Box>
  );
}

import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isSoundOn: true
}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    toggleSoundPlay: (state, action) => {
      state.isSoundOn = action.payload
    },
  },
})

export const getSound = (state) => state.app.isSoundOn

export const { toggleSoundPlay } = appSlice.actions

export default appSlice.reducer


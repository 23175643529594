export const PLACE_HOLDER = "https://www.solidbackgrounds.com/images/1920x1080/1920x1080-gray-solid-color-background.jpg"
export const PLACE_HOLDER_TITLE ="https://plchldr.co/i/600x320?bg=333333&text=title"
export const PLACE_HOLDER_C1 ="https://plchldr.co/i/360x600?bg=aeaeae&text=content1"
export const PLACE_HOLDER_C2 ="https://plchldr.co/i/400x240?bg=909090&text=content2"
export const PLACE_HOLDER_C3 ="https://plchldr.co/i/360x200?bg=5c5c5c&text=content3"
export const PLACE_HOLDER_C4 ="https://plchldr.co/i/520x400?bg=383838&text=content4"
export const PLACE_HOLDER_C5 ="https://plchldr.co/i/630x360?bg=536872&text=content5"
export const PLACE_HOLDER_C6 ="https://plchldr.co/i/200x400?bg=708090&text=content6"
export const PLACE_HOLDER_C7 ="https://plchldr.co/i/400x300?bg=536878&text=content7"
export const PLACE_HOLDER_C8 ="https://plchldr.co/i/200x300?bg=36454f&text=content8"


export const PLACE_HOLDER_C1T ="https://plchldr.co/i/400x600?bg=aeaeae&text=content1"
export const PLACE_HOLDER_C2T ="https://plchldr.co/i/400x300?bg=909090&text=content2"
export const PLACE_HOLDER_C3T ="https://plchldr.co/i/400x600?bg=5c5c5c&text=content3"
export const PLACE_HOLDER_C4T ="https://plchldr.co/i/300x420?bg=383838&text=content4"
export const PLACE_HOLDER_C5T ="https://plchldr.co/i/230x230?bg=536872&text=content5"
export const PLACE_HOLDER_C6T ="https://plchldr.co/i/360x400?bg=5c5c5c&text=content6"
export const PLACE_HOLDER_C7T ="https://plchldr.co/i/200x380?bg=536878&text=content7"


import SliderSick from "react-slick";
import styled from 'styled-components'

const Slider = styled(SliderSick)`

  &&&.slick-slider {
      .slick-list {
        .slick-track {
          display: flex;
          align-items: center;
        }
      }
  }
`
export { Slider }
import React from 'react'
import AppFooter from '../MainNavbar/AppFooter'
// import MainNavbar from '../MainNavbar'
import AppHeader from '../MainNavbar/AppHeader'

export default function LayoutWithNavbar(props) {
  return (
    <div className="w-screen" style={{overflowX:'hidden'}}>
      <AppHeader />
      <div>
        {props.children}
      </div>
      <AppFooter />
    </div>
  )
}

import dummyArray from './dummy.json'
import dummyArray2 from './dummy2.json'

export const DUMMY_DATA_MAIN = [
  {
    id: 1,
    title: "ARCHITECTURE",
    listImgs: dummyArray,
  },
  {
    id: 2,
    title: "ECOLOGY",
    listImgs: dummyArray2,
  },
  {
    id: 3,
    title: "URBANISM",
    listImgs: dummyArray,
  },
  {
    id: 4,
    title: "OBJECTS",
    listImgs: dummyArray2,
  },
  {
    id: 5,
    title: "RITUALS & CUSTOMS",
    listImgs: dummyArray,
  },
]

export const DUMMY_DATA_PROJECT_LISTS = [
  {
    id: 1,
    title: "BANGKOK",
    listImgs: dummyArray,
    subList: [
      { id: 1, title: "ONNUT" },
      { id: 2, title: "THONGLOR" },
      { id: 3, title: "RAMA 9" },
      { id: 4, title: "JARUENG KLUNG" }
    ]
  },
  {
    id: 2,
    title: "CENTRAL THAILAND",
    listImgs: dummyArray2,
    subList: [
      { id: 1, title: "ONNUT" },
      { id: 2, title: "THONGLOR" },
      { id: 3, title: "RAMA 9" },
      { id: 4, title: "JARUENG KLUNG" }
    ]
  },
  {
    id: 3,
    title: "NORTHEASTERN THAILAND",
    listImgs: dummyArray,
    subList: [
      { id: 1, title: "ONNUT" },
      { id: 2, title: "THONGLOR" },
      { id: 3, title: "RAMA 9" },
      { id: 4, title: "JARUENG KLUNG" }
    ]
  },
  {
    id: 4,
    title: "EASTERN THAILAND",
    listImgs: dummyArray2,
    subList: [
      { id: 1, title: "ONNUT" },
      { id: 2, title: "THONGLOR" },
      { id: 3, title: "RAMA 9" },
      { id: 4, title: "JARUENG KLUNG" }
    ]
  },
  {
    id: 5,
    title: "WESTERN THAILAND",
    listImgs: dummyArray,
    subList: [
      { id: 1, title: "ONNUT" },
      { id: 2, title: "THONGLOR" },
      { id: 3, title: "RAMA 9" },
      { id: 4, title: "JARUENG KLUNG" }
    ]
  },
  {
    id: 6,
    title: "SOUTHERN THAILAND",
    listImgs: dummyArray2,
    subList: [
      { id: 1, title: "ONNUT" },
      { id: 2, title: "THONGLOR" },
      { id: 3, title: "RAMA 9" },
      { id: 4, title: "JARUENG KLUNG" }
    ]
  },
  {
    id: 7,
    title: "STATELESS",
    listImgs: dummyArray,
    subList: [
      { id: 1, title: "ONNUT" },
      { id: 2, title: "THONGLOR" },
      { id: 3, title: "RAMA 9" },
      { id: 4, title: "JARUENG KLUNG" }
    ]
  },
  {
    id: 8,
    title: "S.E.A. (SOUTHEAST ASIA)",
    listImgs: dummyArray2,
    subList: [
      { id: 1, title: "ONNUT" },
      { id: 2, title: "THONGLOR" },
      { id: 3, title: "RAMA 9" },
      { id: 4, title: "JARUENG KLUNG" }
    ]
  },
];

export const LOCATION_CHOICE = [
  {
    title: "BANGKOK",
    key: "BANGKOK"
  },
  {
    title: "CENTRAL THAILAND",
    key: "CENTRAL THAILAND"
  },
  {
    title: "NORTHERN THAILAND",
    key: "NORTHERN THAILAND"
  },
  {
    title: "NORTHEASTERN THAILAND",
    key: "NORTHEASTERN THAILAND"
  },
  {
    title: "EASTERN THAILAND",
    key: "EASTERN THAILAND"
  },
  {
    title: "WESTERN THAILAND",
    key: "WESTERN THAILAND"
  },
  {
    title: "SOUTHERN THAILAND",
    key: "SOUTHERN THAILAND"
  },
  {
    title: "STATELESS",
    key: "STATELESS"
  },
  {
    title: "S.E.A. (SOUTHEAST ASIA)",
    key: "S.E.A. (SOUTHEAST ASIA)"
  }
]

export const PROVICE_CHOICE = [
  {
    title: "ONNUT",
    key: "ONNUT"
  },
  {
    title: "THONGLOR",
    key: "THONGLOR"
  },
  {
    title: "RAMA 9",
    key: "RAMA 9"
  },
  {
    title: "JARUENG KLUNG",
    key: "JARUENG KLUNG"
  }
]

export const DOCUMENTER_CHOICE = [
  {
    title: "CHAT LAB",
    key: "CHAT LAB"
  },
  {
    title: "NATIONAL UNIVERSITY OF SINGAPORE (NUS)",
    key: "NATIONAL UNIVERSITY OF SINGAPORE (NUS)"
  },
  {
    title: "CHULALONGKORN UNIVERSITY, INDA",
    key: "CHULALONGKORN UNIVERSITY, INDA"
  },
  {
    title: "TAYLOR'S UNIVERSITY",
    key: "TAYLOR'S UNIVERSITY"
  },
  {
    title: "OTHER",
    key: "OTHER"
  }
]

export const TYPE_CHOICE = [
  {
    title: "BASTARD ARCHITECTURE",
    key: "ARCHITECTURE"
  },
  {
    title: "BASTARD ECOLOGY",
    key: "ECOLOGY"
  },
  {
    title: "BASTARD URBANISM",
    key: "URBANISM"
  },
  {
    title: "BASTARD OBJECTS",
    key: "OBJECTS"
  },
  {
    title: "BASTARD RITUALS & CUSTOMS",
    key: "RITUALS & CUSTOMS"
  },
  // {
  //   title: "OTHER",
  //   key: "OTHER"
  // },
];

export const TEMPLATE_CHOICE = [
  {
    title: "TEMPLATE 1",
    key: 1
  },
  {
    title: "TEMPLATE 2",
    key: 2
  },
  {
    title: "TEMPLATE 3",
    key: 3
  }
];

export const TEMPLATE_ONE_INPUT = [
  {
    title: "Title",
    key: "titleImg"
  },
  {
    title: "Content 1",
    key: "content1"
  },
  {
    title: "Content 2",
    key: "content2"
  },
  {
    title: "Content 3",
    key: "content3"
  },
  {
    title: "Content 4",
    key: "content4"
  },
];

export const TEMPLATE_TWO_INPUT = [
  {
    title: "Title",
    key: "titleImg"
  },
  {
    title: "Content 1",
    key: "content1"
  },
  {
    title: "Content 2",
    key: "content2"
  },
  {
    title: "Content 3",
    key: "content3"
  },
  {
    title: "Content 4",
    key: "content4"
  },
  {
    title: "Content 5",
    key: "content5"
  },
  {
    title: "Content 6",
    key: "content6"
  },
  {
    title: "Content 7",
    key: "content7"
  },
  {
    title: "Content 8",
    key: "content8"
  },
];

export const TEMPLATE_THREE_INPUT = [
  {
    title: "Title",
    key: "titleImg"
  },
  {
    title: "Content 1",
    key: "content1"
  },
  {
    title: "Content 2",
    key: "content2"
  },
  {
    title: "Content 3",
    key: "content3"
  },
  {
    title: "Content 4",
    key: "content4"
  },
  {
    title: "Content 5",
    key: "content5"
  },
  {
    title: "Content 6",
    key: "content6"
  },
  {
    title: "Content 7",
    key: "content7"
  },
];
import React from 'react'
import { Navigate } from 'react-router-dom'

export default function ProtectedRoute({ children, redirectPath = '/bcknd/',
}) {
  const isAuth = localStorage.getItem("@bkkbstdadm")
  if (!isAuth) {
    return <Navigate to={redirectPath} replace />;
  }
  return children
}

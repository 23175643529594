import { createSlice, current } from '@reduxjs/toolkit'
import { ProjectServices } from '../services'

const initialState = {
  projects: [],
  projectDetails: {},
  t1: [],
  t2: [],
  t3: [],
  viewMoreItem:{ 
    title:'Title',
    description: 'Description',
    openStatus: false
  },

}

export const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    addProject: (state, action) => {
      state.projects = action.payload
    },
    updateProjectTemplate: (state, action) => {
      const data = action.payload
      if(!state[`t${action.payload.id}`]) return;
      state[`t${action.payload.id}`].push(data)
      console.log('current ',current(state[`t${action.payload.id}`]))
    },
    getProjectDetail: (state, action) => {
      state.projectDetails = state.projects.find(item => item.id === action.id) ?? {}
    },
    setViewMoreItem: (state, action) => {
      state.viewMoreItem.title = action.payload.title
      state.viewMoreItem.description = action.payload.description
      state.viewMoreItem.openStatus = action.payload.openStatus
    }
  },
  extraReducers: (builder) => {
    builder.addCase(ProjectServices.fetchProject.fulfilled, (state, action) => {
      state.projects = action.payload?.data
    })
    builder.addCase(ProjectServices.createProject.fulfilled, (state, action) => {
      console.log('Upload Success', action.payload)
    })
  }
})

export const t1 = (state) => state.projects.t1
export const t2 = (state) => state.projects.t2
export const t3 = (state) => state.projects.t3
export const projects = (state) => state.projects.projects
export const getViewMoreState = (state) => state.projects.viewMoreItem

export const { addProject, setTemplateByIndex, updateProjectTemplate, setViewMoreItem } = projectSlice.actions

export default projectSlice.reducer


import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';

export default function LoadingWithBackDrop({ message="Loading...", isOpen, closeBackDrop}) {
  const [open, setOpen] = React.useState(true);
  const handleClose = () => setOpen(false);
  const handleToggle = () => setOpen(!open);
  

  return (
    <div>
      <Backdrop
        sx={{ color: '#fff', zIndex: 999}}
        open={isOpen}
        onClick={closeBackDrop}
      >
        <Stack gap={1} justifyContent="center" alignItems="center">
          <CircularProgress color="inherit" />
          <Typography>Uploading...</Typography>
        </Stack>
      </Backdrop>
    </div>
  );
}

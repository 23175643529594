import React, { useState, useCallback } from "react";
import {
  Title,
  TBox,
  TTextBox,
  TMultiBox,
  TUniversalBox,
  TUniversalBoxTitle,
} from "../styles";
import useWindowDimensions from "../../../hook/Dimension";
import MediaOnlyDialog from "../../ModalWidgets/MediaOnlyDialog";
import MediaTextDialog from "../../ModalWidgets/MediaTextDialog";
import ViewMoreDialog from "../../ModalWidgets/ViewMoreDialog";

/*Template Three*/
import ImageTitle from "../../../assets/images/template3/c1.png";
import ImageContent1 from "../../../assets/images/template3/c2.png";
import ImageContent2 from "../../../assets/images/template3/c3.png";
import ImageContent3 from "../../../assets/images/template3/c4.png";
import ImageContent4 from "../../../assets/images/template3/c5.png";
import ImageContent5 from "../../../assets/images/template3/c6.png";
import ImageContent6 from "../../../assets/images/template3/c7.png";
import ImageContent7 from "../../../assets/images/template3/c8.png";

import {
  PLACE_HOLDER_C1,
  PLACE_HOLDER_C2,
  PLACE_HOLDER_C3,
  PLACE_HOLDER_C4,
  PLACE_HOLDER_C5,
  PLACE_HOLDER_C6,
  PLACE_HOLDER_C7,
  PLACE_HOLDER_C8,
  PLACE_HOLDER_TITLE,
} from "../../TemplateWidgets/constant";

export default function T3(props) {
  const {
    form,
    templateGalleries = [],
    preview,
    previewSelectHandler,
    isAdmin = false,
  } = props;
  const { width } = useWindowDimensions();
  const padVal = width >= 640 ? 96 : 32;
  const widthPercentage = width - padVal;
  const isMobileSize = width < 640;
  const isResponsive = isMobileSize && !isAdmin;

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openMediaText, setOpenMediaText] = useState(false);
  const handleMediaTextOpen = () => setOpenMediaText(true);
  const handleMediaTextClose = () => setOpenMediaText(false);
  const [contentId, setContentId] = useState(0);

  const contentClickHandler = useCallback((contentId = 0) => {
    if (!preview) {
      contentId ? handleMediaTextOpen() : handleOpen()
      setContentId(contentId)
      return;
    }
    previewSelectHandler(templateGalleries[contentId])
  }, [preview])

  return (
    <>
      <ViewMoreDialog />
      {!preview && (
        <>
          <MediaOnlyDialog
            form={form}
            handleClickOpen={handleOpen}
            open={open}
            handleClose={handleClose}
            templateId={2}
          />
          <MediaTextDialog
            form={form}
            handleClickOpen={handleOpen}
            openMediaText={openMediaText}
            handleMediaTextClose={handleMediaTextClose}
            contentId={contentId}
            templateId={2}
          />
        </>
      )}
      {/* <div
        style={{
          width: widthPercentage,
          height: isResponsive ? "auto" : widthPercentage * 0.84,
          overflow: isResponsive ? "auto" : "hidden",
        }}
      > */}
      <div style={{ marginBottom: 8, position: "relative" }} onClick={() => contentClickHandler(0)}>
        <img src={ImageTitle} className="img-base" alt="Title" is-admin={isAdmin ? "true" : "false"} />
        <TUniversalBoxTitle
          isAdmin={isAdmin}
          url={
            templateGalleries[0]?.url_desktop
              ? templateGalleries[0]?.url_desktop
              : PLACE_HOLDER_TITLE
          }
          mbUrl={
            templateGalleries[0]?.url_mobile
              ? templateGalleries[0]?.url_mobile
              : templateGalleries[0]?.url_desktop || PLACE_HOLDER_TITLE
          }
        />
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: isResponsive ? "column" : "row",
        }}
      >
        <div style={{ position: "relative" }} className="right-gutter" is-admin={isAdmin ? "true" : "false"}>
          <div style={{ position: "relative" }}>
            <img src={ImageContent1} className="img-base" alt="Content 1" is-admin={isAdmin ? "true" : "false"} />
            <TUniversalBox
              isAdmin={isAdmin}
              bgColor="#8e8e8e"
              useDesktop={templateGalleries[1]?.use_desktop}
              type={templateGalleries[1]?.filetype}
              url={
                templateGalleries[1]?.url_desktop
                  ? templateGalleries[1]?.url_desktop
                  : PLACE_HOLDER_C1
              }
              mbUrl={
                templateGalleries[1]?.url_mobile
                  ? templateGalleries[1]?.url_mobile
                  : templateGalleries[1]?.url_desktop || PLACE_HOLDER_C1
              }
              onClick={() => contentClickHandler(1)}
              title={templateGalleries[1]?.title_en}
              description={templateGalleries[1]?.description_en}
              className="clamp-xl"
            />
          </div>
          <div style={{ position: "relative" }} className="xs-gutter-top" is-admin={isAdmin ? "true" : "false"}>
            <img src={ImageContent2} className="img-base" alt="Content 2" is-admin={isAdmin ? "true" : "false"} />
            <TUniversalBox
              isAdmin={isAdmin}
              useDesktop={templateGalleries[2]?.use_desktop}
              type={templateGalleries[2]?.filetype}
              onClick={() => contentClickHandler(2)}
              url={
                templateGalleries[2]?.url_desktop
                  ? templateGalleries[2]?.url_desktop
                  : PLACE_HOLDER_C2
              }
              mbUrl={
                templateGalleries[2]?.url_mobile
                  ? templateGalleries[2]?.url_mobile
                  : templateGalleries[2]?.url_desktop || PLACE_HOLDER_C2
              }
              title={templateGalleries[2].title_en}
              description={templateGalleries[2].description_en}
              className="clamp-xs"
            />
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ display: "flex", flexDirection: isResponsive ? "column" : "row" }}>
            <div style={{ position: "relative" }} className="xs-gutter-top" is-admin={isAdmin ? "true" : "false"}>
              <img src={ImageContent3} alt="Content 3" className="img-base" is-admin={isAdmin ? "true" : "false"} />
              <TUniversalBox
                useDesktop={templateGalleries[3]?.use_desktop}
                isAdmin={isAdmin}
                type={templateGalleries[3]?.filetype}
                onClick={() => contentClickHandler(3)}
                url={
                  templateGalleries[3]?.url_desktop
                    ? templateGalleries[3]?.url_desktop
                    : PLACE_HOLDER_C3
                }
                mbUrl={
                  templateGalleries[3]?.url_mobile
                    ? templateGalleries[3]?.url_mobile
                    : templateGalleries[3]?.url_desktop || PLACE_HOLDER_C3
                }
                title={templateGalleries[3]?.title_en}
                description={templateGalleries[3]?.description_en}
                className="clamp-xl"
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
              className="left-gutter"
              is-admin={isAdmin ? "true" : "false"}
            >
              <div style={{ position: "relative" }} className="xs-gutter-top" is-admin={isAdmin ? "true" : "false"}>
                <img
                  src={ImageContent4}
                  alt="Content 4"
                  className="img-base"
                  is-admin={isAdmin ? "true" : "false"}
                />
                <TUniversalBox
                  useDesktop={templateGalleries[4]?.use_desktop}
                  bgColor="#8e8e8e"
                  isAdmin={isAdmin}
                  type={templateGalleries[4]?.filetype}
                  onClick={() => contentClickHandler(4)}
                  url={
                    templateGalleries[4]?.url_desktop
                      ? templateGalleries[4]?.url_desktop
                      : PLACE_HOLDER_C4
                  }
                  mbUrl={
                    templateGalleries[4]?.url_mobile
                      ? templateGalleries[4]?.url_mobile
                      : templateGalleries[4]?.url_desktop || PLACE_HOLDER_C4
                  }
                  title={templateGalleries[4]?.title_en}
                  description={templateGalleries[4]?.description_en}
                  className="clamp-md"
                />
              </div>
              <div style={{ position: "relative" }} className="xs-gutter-top" is-admin={isAdmin ? "true" : "false"}>
                <img
                  src={ImageContent5}
                  alt="Content 5"
                  className="img-base"
                  is-admin={isAdmin ? "true" : "false"}
                />
                <TUniversalBox
                  isAdmin={isAdmin}
                  type={templateGalleries[5]?.filetype}
                  useDesktop={templateGalleries[5]?.use_desktop}
                  onClick={() => contentClickHandler(5)}
                  url={
                    templateGalleries[5]?.url_desktop
                      ? templateGalleries[5]?.url_desktop
                      : PLACE_HOLDER_C5
                  }
                  mbUrl={
                    templateGalleries[5]?.url_mobile
                      ? templateGalleries[5]?.url_mobile
                      : templateGalleries[5]?.url_desktop || PLACE_HOLDER_C5
                  }
                  title={templateGalleries[5]?.title_en}
                  description={templateGalleries[5]?.description_en}
                  className="clamp-xs"
                />
              </div>
            </div>
          </div>
          <div
            style={{ display: "flex", flexDirection: isResponsive ? "column" : "row", marginTop: 8 }}
          >
            <div style={{ position: "relative" }} className="xs-gutter-top" is-admin={isAdmin ? "true" : "false"}>
              <img src={ImageContent6} alt="Content 6" className="img-base" is-admin={isAdmin ? "true" : "false"} />
              <TUniversalBox
                isAdmin={isAdmin}
                useDesktop={templateGalleries[6]?.use_desktop}
                type={templateGalleries[6]?.filetype}
                url={
                  templateGalleries[6]?.url_desktop
                    ? templateGalleries[6]?.url_desktop
                    : PLACE_HOLDER_C6
                }
                mbUrl={
                  templateGalleries[6]?.url_mobile
                    ? templateGalleries[6]?.url_mobile
                    : templateGalleries[6]?.url_desktop || PLACE_HOLDER_C6
                }
                onClick={() => contentClickHandler(6)}
                title={templateGalleries[6]?.title_en}
                description={templateGalleries[6]?.description_en}
                className="clamp-md"
              />
            </div>
            <div style={{ position: "relative" }} className="xs-gutter-top" is-admin={isAdmin ? "true" : "false"}>
              <img src={ImageContent7} alt="Content 6" className="img-base" is-admin={isAdmin ? "true" : "false"} />
              <TUniversalBox
                bgColor="#8e8e8e"
                isAdmin={isAdmin}
                type={templateGalleries[7]?.filetype}
                useDesktop={templateGalleries[7]?.use_desktop}
                url={
                  templateGalleries[7]?.url_desktop
                    ? templateGalleries[7]?.url_desktop
                    : PLACE_HOLDER_C7
                }
                mbUrl={
                  templateGalleries[7]?.url_mobile
                    ? templateGalleries[7]?.url_mobile
                    : templateGalleries[7]?.url_desktop || PLACE_HOLDER_C7
                }
                onClick={() => contentClickHandler(7)}
                title={templateGalleries[7]?.title_en}
                description={templateGalleries[7]?.description_en}
                className="clamp-xs"
              />
            </div>
          </div>
        </div>
      </div>
      {/* <div key="title" onClick={handleOpen}>
          <Title
            isAdmin={isAdmin}
            width={widthPercentage}
            height={widthPercentage * 0.22}
            url={
              templateGalleries[0]?.url_desktop
                ? templateGalleries[0]?.url_desktop
                : PLACE_HOLDER_TITLE
            }
            mbUrl={
              templateGalleries[0]?.url_mobile
                ? templateGalleries[0]?.url_mobile
                : templateGalleries[0]?.url_desktop || PLACE_HOLDER_TITLE
            }
          />
        </div>
        <div
          style={{
            width: widthPercentage,
            height: isResponsive ? "auto" : widthPercentage * 0.62,
            backgroundColor: "#f3f3f3",
            flexDirection: isResponsive ? "column" : "row",
            display: "flex",
            justifyContent: "stretch",
          }}
        >
          <div
            style={{
              flex: 1.4,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <TMultiBox
              isAdmin={isAdmin}
              type={templateGalleries[1]?.filetype}
              onClick={() => contentClickHandler(1)}
              url={
                templateGalleries[1]?.url_desktop
                  ? templateGalleries[1]?.url_desktop
                  : PLACE_HOLDER_C1
              }
              mbUrl={
                templateGalleries[1]?.url_mobile
                  ? templateGalleries[1]?.url_mobile
                  : templateGalleries[1]?.url_desktop || PLACE_HOLDER_C1
              }
              flex={1.6}
              margin={"4px 4px 0"}
              title={templateGalleries[1]?.title_en}
              description={templateGalleries[1]?.description_en}
              className="t3c1"
            />
            <TMultiBox
              isAdmin={isAdmin}
              type={templateGalleries[2]?.filetype}
              onClick={() => contentClickHandler(2)}
              url={
                templateGalleries[2]?.url_desktop
                  ? templateGalleries[2]?.url_desktop
                  : PLACE_HOLDER_C2
              }
              mbUrl={
                templateGalleries[2]?.url_mobile
                  ? templateGalleries[2]?.url_mobile
                  : templateGalleries[2]?.url_desktop || PLACE_HOLDER_C2
              }
              flex={1}
              margin={"0 4px 0"}
              className="t3c2"
              bgColor="#555"
            />
          </div>
          <div
            style={{
              flex: 3,
              flexDirection: "column",
              display: "flex",
            }}
          >
            <div
              style={{
                flex: 4,
                flexDirection: isResponsive ? "column" : "row",
                display: "flex",
                justifyContent: "stretch",
              }}
            >
              <TMultiBox
                isAdmin={isAdmin}
                type={templateGalleries[3]?.filetype}
                onClick={() => contentClickHandler(3)}
                url={
                  templateGalleries[3]?.url_desktop
                    ? templateGalleries[3]?.url_desktop
                    : PLACE_HOLDER_C3
                }
                mbUrl={
                  templateGalleries[3]?.url_mobile
                    ? templateGalleries[3]?.url_mobile
                    : templateGalleries[3]?.url_desktop || PLACE_HOLDER_C3
                }
                title={templateGalleries[3]?.title_en}
                description={templateGalleries[3]?.description_en}
                flex={1.6}
                className="t3c3"
              />
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <TMultiBox
                  isAdmin={isAdmin}
                  type={templateGalleries[4]?.filetype}
                  onClick={() => contentClickHandler(4)}
                  url={
                    templateGalleries[4]?.url_desktop
                      ? templateGalleries[4]?.url_desktop
                      : PLACE_HOLDER_C4
                  }
                  mbUrl={
                    templateGalleries[4]?.url_mobile
                      ? templateGalleries[4]?.url_mobile
                      : templateGalleries[4]?.url_desktop || PLACE_HOLDER_C4
                  }
                  title={templateGalleries[4]?.title_en}
                  description={templateGalleries[4]?.description_en}
                  flex={2}
                  margin={"4px 4px 0"}
                  className="t3c4"
                />
                <TMultiBox
                  isAdmin={isAdmin}
                  type={templateGalleries[5]?.filetype}
                  onClick={() => contentClickHandler(5)}
                  url={
                    templateGalleries[5]?.url_desktop
                      ? templateGalleries[5]?.url_desktop
                      : PLACE_HOLDER_C5
                  }
                  mbUrl={
                    templateGalleries[5]?.url_mobile
                      ? templateGalleries[5]?.url_mobile
                      : templateGalleries[5]?.url_desktop || PLACE_HOLDER_C5
                  }
                  title={templateGalleries[5]?.title_en}
                  description={templateGalleries[5]?.description_en}
                  flex={1}
                  margin={"0 4px 4px"}
                  bgColor="#555"
                  className="t3c5"
                />
              </div>
            </div>
            <div
              style={{
                flex: 2,
                display: "flex",
                flexDirection: isResponsive ? "column" : "row",
              }}
            >
              <TMultiBox
                type={templateGalleries[6]?.filetype}
                url={
                  templateGalleries[6]?.url_desktop
                    ? templateGalleries[6]?.url_desktop
                    : PLACE_HOLDER_C6
                }
                mbUrl={
                  templateGalleries[6]?.url_mobile
                    ? templateGalleries[6]?.url_mobile
                    : templateGalleries[6]?.url_desktop || PLACE_HOLDER_C6
                }
                onClick={() => contentClickHandler(6)}
                title={templateGalleries[6].title}
                description={templateGalleries[6].description}
                flex={2.5}
                margin={"4px 0 0 4px"}
                className="t3c6"
              />
              <TMultiBox
                isAdmin={isAdmin}
                type={templateGalleries[7]?.filetype}
                url={
                  templateGalleries[7]?.url_desktop
                    ? templateGalleries[7]?.url_desktop
                    : PLACE_HOLDER_C7
                }
                mbUrl={
                  templateGalleries[7]?.url_mobile
                    ? templateGalleries[7]?.url_mobile
                    : templateGalleries[7]?.url_desktop || PLACE_HOLDER_C7
                }
                onClick={() => contentClickHandler(7)}
                title={templateGalleries[7]?.title_en}
                description={templateGalleries[7]?.description_en}
                flex={1}
                margin={"4px 4px 0 0"}
                className="t3c7"
                bgColor="#555"
              />
            </div>
          </div>
        </div> */}
      {/* </div> */}
    </>
  );
}

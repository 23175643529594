import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Modal } from "@mui/material";
import React from "react";
import useWindowDimensions from "../../hook/Dimension";
import { Slider } from "./styles";

const styles = {
  nextStyle: {
    color: "#fff",
    position: "absolute",
    top: "50%",
    right: -30,
    zIndex: 999,
    cursor: "pointer",
    marginTop: -12,
  },
  prevStyle: {
    color: "#fff",
    position: "absolute",
    top: "50%",
    left: -30,
    zIndex: 999,
    cursor: "pointer",
    marginTop: -12,
  },
};
const AppNextArrow = ({ className, style, onClick }) => (
  <ArrowForwardIosIcon onClick={onClick} style={styles.nextStyle} />
);
const AppPrevArrow = ({ className, style, onClick }) => (
  <ArrowBackIosNewIcon onClick={onClick} style={styles.prevStyle} />
);

export default function ModalImage({ data, previewSelected, open, setIsOpen }) {
  const { width } = useWindowDimensions();
  const isVideo = React.useCallback((url = "") => {
    const videos = ["mp4", "3gp", "ogg"];
    const extension = url?.split(".").pop();
    return videos.includes(extension);
  }, []);

  const slideWidth = width * 0.8;
  const slideHight = width * 0.42;

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -47%)",
    width: 400,
  };

  return (
    <Modal
      open={open}
      onClose={() => setIsOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ background: "rgba(0,0,0,0.92)" }}
      hideBackdrop={true}
    >
      <Box sx={{ ...style, width: "80%", position: "relative" }}>
        <div
          style={{
            padding: "10px 0",
            textAlign: "right",
            position: "relative",
            right: -30,
            top: 30
          }}
        >
          <CloseIcon
            style={{
              color: "#fff",
              cursor: "pointer",
            }}
            onClick={() => setIsOpen(false)}
          />
        </div>
        <Slider
          initialSlide={previewSelected}
          slidesToShow={1}
          swipeToSlide={false}
          nextArrow={<AppNextArrow />}
          prevArrow={<AppPrevArrow />}
        >
          {data &&
            data.flatMap((item, index) => {
              if (item.src) {
                return (
                  <div key={index} className="text-center">
                    <div
                      style={{
                        width: slideWidth,
                        height: slideHight,
                        position: "relative",
                      }}
                    >
                      {isVideo(item.src) ? (
                        <video
                          autoPlay
                          muted
                          loop
                          style={{
                            width: "auto",
                            height: "100%",
                            position: "absolute",
                            left: "50%",
                            top: "50%",
                            transform: "translate(-50%,-50%)",
                            background: "#2c2c2c",
                          }}
                        >
                          <source src={item.src} type="video/mp4" />
                        </video>
                      ) : (
                        <img
                          src={item.src}
                          alt=""
                          style={{
                            height: "auto",
                            width: "auto",
                            maxHeight: 750,
                            position: "absolute",
                            left: "50%",
                            top: "50%",
                            transform: "translate(-50%,-50%)",
                          }}
                        />
                      )}
                    </div>
                    {!!item.caption && (
                      <p className="font-sarabun text-white m-2">
                        {item.caption}
                      </p>
                    )}
                  </div>
                );
              }
              return [];
            })}
        </Slider>
      </Box>
    </Modal>
  );
}

import { ArrowBack } from "@mui/icons-material";
import { Icon } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Animated } from "react-animated-css";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ImageSlider, LayoutWithNavbar } from "../../components";
import { getProjectBySubArea } from "../../services/projectService";
import { TitleContainer } from "../mainStyles";

export default function SubProjectScreen() {
  const { category, id } = useParams();
  const [selectedData, setSelectedData] = useState({});
  const [slides, setSlides] = useState([]);
  const [height, setHeight] = useState(0);
  const location = useLocation();
  const elmRef = useRef(null);
  const handleBackPage = () => {
    location?.key ? navigate(-1) : navigate("/");
  };
  const navigate = useNavigate();
  useEffect(() => {
    const fetchSlidesData = async () => {
      try {
        let { success, data } = await getProjectBySubArea(id);
        if (success) {
          setSlides(data);
        }
      } catch (e) {
        console.log(e.message);
      }
    };
    fetchSlidesData();
    if (elmRef && elmRef.current) {
      setHeight(elmRef.current.clientHeight);
    }
    const handleResize = () => setHeight(elmRef.current.clientHeight);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <LayoutWithNavbar>
      <Helmet>
        <title>BANGKOK BASTARDS | {id.replace(/\s/g, " ").toUpperCase()}</title>
        <meta
          name="keywords"
          content="Bangkok Bastards, Bangkok Bastard, CHAT architects,สถาปัตยกรรมชั้นเลว,, สถาปัตยกรรมที่ไม่น่าพิสมัย,บ้านคนงานก่อสร้าง,สลัม,แผงลอยเป็นสถาปัตยกรรมชั้นต่ำ,บ้านคนงานก่อสร้าง"
        />
        <meta name="author" content="CHAT architects" />
        <meta
          name="description"
          content="Bangkok Bastards describes the research subjects of CHAT architects. From construction worker houses to forgotten shantytowns, from illegal pop-up markets to street vendor carts, from seedy massage parlors to underground sex motels, these vernacular Bastards are hybrids of questionable origins, scattered though out the city."
        />
        <meta
          itemprop="name"
          content="สถาปัตยกรรมสารเลว กับมุมมองที่แตกต่างของคุณฉัตรพงษ์ ชื่นฤดีมล&nbsp;แห่ง CHAT Architects"
        />
        <meta
          itemprop="headline"
          content="สถาปัตยกรรมสารเลว กับมุมมองที่แตกต่างของคุณฉัตรพงษ์ ชื่นฤดีมล&nbsp;แห่ง CHAT Architects"
        />
        <meta
          itemprop="image"
          content="https://bangkokbastards.s3.ap-southeast-1.amazonaws.com/1660290435772-share.jpg"
        />
        <meta property="og:url" content="https://www.bangkokbastards.com" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content={`BANGKOK BASTARDS | ${category.toUpperCase()}`}
        />
        <meta
          property="og:description"
          content="Bangkok Bastards describes the research subjects of CHAT architects. From construction worker houses to forgotten shantytowns, from illegal pop-up markets to street vendor carts, from seedy massage parlors to underground sex motels, these vernacular Bastards are hybrids of questionable origins, scattered though out the city"
        />
        <meta
          property="og:image"
          content="https://bangkokbastards.s3.ap-southeast-1.amazonaws.com/1660290435772-share.jpg"
        />
      </Helmet>
      <div
        className="justify-center text-5xl sm:text-7xl  md:text-8xl lg:text-9xl text-secondary font-impact text-center sticky-header"
        ref={elmRef}
      >
        <div
          onClick={handleBackPage}
          className="mx-5 md:mx-12 flex flex-row cursor-pointer relative z-50"
        >
          <Icon component={ArrowBack} fontSize="small" />
          <p className="font-sarabun text-sm ml-1 font-semibold">Back</p>
        </div>
        <div className="flex flex-row justify-center">
          <div className="text-primary max-w-[85%]">
            {id?.replace(/\s|[-]/g, " ").toUpperCase()}{" "}
            <span className="text-secondary">BASTARDS</span>
            {window.location.pathname?.split("/").pop() ===
              "central-thailand" && (
              <p className="text-secondary text-lg sm:text-3xl">
                [OUTSIDE BANGKOK]
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="list-content-wrapper" style={{ paddingTop: height }}>
        {/* <div className="mx-4 sm:mx-12 md:mx-12 lg:mx-12">
          <Divider />
        </div> */}

        {slides.map((item, index) => {
          return (
            <div key={index} className="my-5 md:my-12">
              <TitleContainer className="cursor-pointer items-center">
                <Animated
                  animationIn="fadeIn"
                  animationOut="fadeOut"
                  isVisible={true}
                >
                  <p className="text-secondary">
                    {item.title}
                    <span className="text-secondary"> {item.type}</span>{" "}
                    {category === "location" && (
                      <span className="text-secondary txt--default"></span>
                    )}
                  </p>
                </Animated>
                {/* <Icon className="ml-2" component={ChevronRight} fontSize="inherit" /> */}
              </TitleContainer>

              <ImageSlider data={item.projects} />
            </div>
          );
        })}
      </div>
    </LayoutWithNavbar>
  );
}

import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import ArrowDown from '../../assets/images/arrow-down.gif'
import Logo from '../../assets/images/logo.gif'
import { ImageSlider, LayoutWithNavbar } from '../../components'
import { TitleContainer } from '../mainStyles'



import { Animated } from "react-animated-css"
import { useDispatch, useSelector } from "react-redux"
import { RouterContext } from '../../App'
import { getSound } from "../../redux/appSlice"
import { getProjectByTypes } from '../../services/projectService'
const MainScreen = () => {
  const [slides, setSlides] = useState([])
  const {audioPlay, setAudio } = React.useContext(RouterContext)
  useEffect(() => {
    const fetchSlidesData = async () => {
      try{
       let {success, data} = await getProjectByTypes()
        if(success){
          setSlides(data)
        }
        }catch(e){
          console.log(e.message)
        }
    }
    fetchSlidesData()
  },[])
  const isSoundOn  = useSelector(getSound)
  const dispatch = useDispatch()

  const playMusic = () => {
    document.getElementById('audio-sound').play();
    // dispatch(toggleSoundPlay(!isSoundOn))
    // setAudio(true)
  }

  return (
    <LayoutWithNavbar>
      <Helmet>
        <title>BANGKOK BASTARDS</title>
        <meta name="keywords" content="Bangkok Bastards, Bangkok Bastard, CHAT architects,สถาปัตยกรรมชั้นเลว,, สถาปัตยกรรมที่ไม่น่าพิสมัย,บ้านคนงานก่อสร้าง,สลัม,แผงลอยเป็นสถาปัตยกรรมชั้นต่ำ,บ้านคนงานก่อสร้าง" />
        <meta name="author" content="CHAT architects" />
        <meta name="description" content="Bangkok Bastards describes the research subjects of CHAT architects. From construction worker houses to forgotten shantytowns, from illegal pop-up markets to street vendor carts, from seedy massage parlors to underground sex motels, these vernacular Bastards are hybrids of questionable origins, scattered though out the city." />
        <meta itemprop="name" content="สถาปัตยกรรมสารเลว กับมุมมองที่แตกต่างของคุณฉัตรพงษ์ ชื่นฤดีมล&nbsp;แห่ง CHAT Architects" />
        <meta itemprop="headline" content="สถาปัตยกรรมสารเลว กับมุมมองที่แตกต่างของคุณฉัตรพงษ์ ชื่นฤดีมล&nbsp;แห่ง CHAT Architects" />
        <meta itemprop="image" content="https://bangkokbastards.s3.ap-southeast-1.amazonaws.com/1660290435772-share.jpg" />
        <meta property="og:url"  content="https://www.bangkokbastards.com" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="BANGKOK BASTARDS" />
        <meta property="og:description" content="Bangkok Bastards describes the research subjects of CHAT architects. From construction worker houses to forgotten shantytowns, from illegal pop-up markets to street vendor carts, from seedy massage parlors to underground sex motels, these vernacular Bastards are hybrids of questionable origins, scattered though out the city" />
        <meta property="og:image" content="https://bangkokbastards.s3.ap-southeast-1.amazonaws.com/1660290435772-share.jpg" />
      </Helmet>
      <div className="w-full px-4 sm:px-12 md:px-12 lg:px-12 my-10 cursor-pointer" >
        <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={true}>
          <img src={Logo} alt="logo" className="object-cover w-full banner-img" onClick={playMusic}/>
        </Animated>
        
       
      </div>
      <div className='text-center'> <img src={ArrowDown} style={{display:'inline', width: 32}} alt="" /></div>
      <div>
        <div className="mt-10">
          {slides.map((item, index) => {
            return (
              <Animated key={index} animationIn="fadeIn" animationOut="fadeOut" isVisible={true}>
                <div className="my-5 md:my-12">

                  <TitleContainer TitleContainer >
                    <p className="text-secondary font-impact"><span className="txt--default">BASTARD</span> <span className="text-primary txt--success">{item.type}</span></p>
                  </TitleContainer>
                  <ImageSlider
                    data={item.projects}
                    isModal={false}
                  />
                </div>
              </Animated>
            )
          })}
        </div>
      </div>
    </LayoutWithNavbar>
  )
}

export default MainScreen


import {
  Container,
  Link,
  Stack,
  Button,
  Popover,
  Snackbar,
  Alert,
  CircularProgress,
  Backdrop,
  Box
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid'

import React, { useCallback, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { ProjectServices } from '../../services'
import { useNavigate } from 'react-router-dom';
import { deletedProjectHandler, hardDeleteProjectHandler, publishProjectHandler } from '../../services/projectService';
import { ButtonSmall } from './styles';
import { isArray } from 'lodash';
import { get } from 'lodash';
import moment from 'moment';

import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import PublicIcon from '@mui/icons-material/Public';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PublicOffIcon from '@mui/icons-material/PublicOff';

export default function BckProjectList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { projects } = useSelector(state => state.projects)
  const [dataSource, setDataSource] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openSnack, setOpenSnack] = useState(false);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('success');
  const [removeId, setRemoveId] = useState(null)

  useEffect(() => {
    if (isArray(projects) && projects.length > 0) {
      const dataArray = projects.map((item) => {
        return {
          id: item._id,
          title: item.title?.th,
          location: item.location?.main_area + "," + item.location?.sub_area,
          documenters: get(item, "documenters[0].name", ""),
          type: get(item, "types[0].name", ""),
          templateId: item.template_id,
          createdAt: item.created_at,
          active: item.active
        }
      })
      setDataSource(dataArray)
    }
    // document.getElementById("audio-sound").pause();

    
  }, [projects])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  useEffect(() => {
    dispatch(ProjectServices.fetchProject({ active: 'all' }))
    // dispatch(ProjectServices.fetchProject({active: false}))
    // dispatch(ProjectServices.fetchProject({active: true}))
  }, [])

  const onUpdateHandler = useCallback((id) => {
    navigate(`/bcknd/add-template/${id}`)
  }, [])

  const deletProjectHandler = async () => {
   
    try {
      handleClose()
      setLoading(true)
      const response = await hardDeleteProjectHandler(removeId);
      if (response.success) {
        setStatus('success')
        setOpenSnack(true);
        dispatch(ProjectServices.fetchProject({ active: 'all' }))
        setLoading(false)
      }
    } catch (e) {
      setLoading(false)
      setStatus('error')
      setOpenSnack(true);
      handleClose()
    }

  }
  const toggleViewProjectHandler = async (projectId, state) => {
    try {
      handleClose()
      setLoading(true)
      const response = !state?  await publishProjectHandler(projectId): await deletedProjectHandler(projectId);
      if (response.success) {
        setStatus('success')
        setOpenSnack(true);
        dispatch(ProjectServices.fetchProject({ active: 'all' }))
        setLoading(false)
      }
    } catch (e) {
      setLoading(false)
      setStatus('error')
      setOpenSnack(true);
      handleClose()
    }

  }

  const COLUMNS = [
    {
      field: 'title',
      headerName: 'Title',
      minWidth: 300,
      sortable: false,
      hideable: false
    },
    {
      field: 'location',
      headerName: 'Location',
      minWidth: 350,
      sortable: false,
      hideable: false
    },
    {
      field: 'documenters',
      headerName: 'Documenters',
      align: 'center',
      sortable: false,
      hideable: false
    },
    {
      field: 'type',
      headerName: 'Type',
      minWidth: 200,
      sortable: false,
      hideable: false
    },
    {
      field: 'templateId',
      headerName: 'Template No.',
      align: 'center',
      type: 'number',
      sortable: false,
      hideable: false
    },
    {
      field: 'createdAt',
      headerName: 'Created time',
      align: 'center',
      headerAlign: 'center',
      minWidth: 150,
      type: 'number',
      sortable: false,
      hideable: false,
      valueGetter: (params) => {
        return (
          moment(params.row.createdAt).format("DD MMMM YYYY HH:mm")
        )
      }
    },
    {
      field: 'id',
      headerName: 'Action',
      flex: 1,
      align: 'center',
      minWidth: 280,
      headerAlign: 'center',
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={1} justifyContent="space-evenly">
            {/* <Button onClick={() => window.open(`/project-detail/${params.id}`, '_blank')} size="small" variant="contained" color="info">
              View
            </Button> */}
            <Box justifyContent="center" alignItems="center" flex textAlign="center">
              <IconButton aria-label="view" onClick={() => window.open(`/project-detail/${params.id}`, '_blank')} size="small" variant="contained" color="info">
                <VisibilityIcon fontSize="medium" color="info" />
              </IconButton>
              <p style={{fontSize: 12, marginTop: -6}}>View</p>
            </Box>
            
            {/* <Button onClick={() => onUpdateHandler(params.id)} size="small" variant="contained" color="warning">
              Edit
            </Button> */}
            <Box justifyContent="center" alignItems="center" flex textAlign="center">
              <IconButton aria-label="edit" onClick={() => onUpdateHandler(params.id)} size="small" variant="contained">
                  <EditIcon fontSize="medium" color="warning" />
                </IconButton>
                <p style={{fontSize: 12, marginTop: -6}}>Edit</p>
            </Box>
             
            {/* <Button onClick={() => onUpdateHandler(params.id)} size="small" variant="contained" color="primary">
              PUBLISHED
            </Button> */}
            <Box justifyContent="center" alignItems="center" flex textAlign="center">
              <IconButton aria-label="publish" onClick={() => toggleViewProjectHandler(params.id, params.row.active)} size="small" variant="contained">
                
                {
                  params.row.active ?
                  <PublicIcon fontSize="medium" color='success' />:
                  <PublicOffIcon fontSize="medium" color='nuetral' />

                }
                
              </IconButton>
              <p style={{fontSize: 12, marginTop: -6, minWidth: 52}}>{params.row.active ?'Published': 'Saved'}</p>
              </Box>
            <div>
              {/* <Button onClick={(event) => {handleClick(event); setRemoveId(params.id)}} aria-describedby={id} size="small" variant="contained" color="error">
                Delete
              </Button> */}
              <Box justifyContent="center" alignItems="center" flex textAlign="center">
                <IconButton aria-label="delete" onClick={(event) => {handleClick(event); setRemoveId(params.id)}} size="small" aria-describedby={id}  variant="contained">
                  <DeleteIcon fontSize="medium" color='error' />
                </IconButton>
                <p style={{fontSize: 12, marginTop: -6, minWidth: 45}}>Delete</p>
              </Box>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'center',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <div className="p-2">
                  <p className="font-sarabun text-sm">คุณต้องการลบโปรเจคหรือไม่?</p>
                  <div className="flex flex-row justify-end mt-1">
                    <ButtonSmall onClick={handleClose} size="small" variant="text" className="min-w-0">
                      ยกเลิก
                    </ButtonSmall>
                    <ButtonSmall onClick={() => deletProjectHandler()} size="small" variant="text" color="error" className="min-w-0">
                      ยืนยัน
                    </ButtonSmall>
                  </div>
                </div>
              </Popover>
            </div>
          </Stack>
        )
      }
    },
  ];
  const [pageSize, setPageSize] = React.useState(10);
  
  const signOut = () => {
    localStorage.removeItem("@bkkbstdadm")
    window.location.pathname = '/bcknd/'
  }
  const createNewProject = () => window.location.pathname = '/bcknd/add-template'
  return (
    <Container maxWidth="xl">
      <Backdrop
        sx={{ color: '#fff', zIndex: 999 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={openSnack} autoHideDuration={3000} onClose={() => setOpenSnack(false)}>
        {status === "error" ?
          <Alert onClose={() => setOpenSnack(false)} severity="error" sx={{ width: '100%' }}>
            เกิดข้อผิดพลาด
          </Alert> :
          <Alert onClose={() => setOpenSnack(false)} severity="success" sx={{ width: '100%' }}>
            สำเร็จ
          </Alert>}
      </Snackbar>
      <div className="flex flex-row justify-center text-5xl sm:text-7xl  font-bold font-impact mt-6 text-center">
        <p className="text-primary">PROJECT <span className="text-black">LIST</span></p>
      </div>
      <div className='flex flex-row justify-center'>
        <div className='text-center py-4 font-sarabun text-md  mx-2'><Button variant="text" onClick={createNewProject}>คลิกเพื่อสร้างโปรเจ็ค</Button></div>
        <div className='text-center py-4 font-sarabun text-md  mx-2'><Button variant="text" onClick={signOut}>ออกจากระบบ</Button></div>
      </div>
      
      <div style={{ width: '100%', height: 640 }}>
        {dataSource.length > 0 && (
          <DataGrid
            rows={dataSource}
            columns={COLUMNS}
            pageSize={pageSize}
            checkboxSelection={false}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[10, 20, 50]}
            pagination
          />)
        }
      </div>
    </Container>
  )
}

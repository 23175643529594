import * as React from "react";
import {
  Divider,
  Box,
  Grid,
  Link,
  InputLabel,
  MenuItem,
  FormControl,
  ListItemIcon,
  ListItemText,
  Button,
  Menu,
  Select,
  Container,
  Typography,
  Stack,
} from "@mui/material";
import LanguageIcon from "@mui/icons-material/Language";
import FacebookIcon from "@mui/icons-material/Facebook";
import CallIcon from "@mui/icons-material/Call";
import MailIcon from "@mui/icons-material/Mail";
import { useStyles } from "./navStyle";


export default function AppFooter() {
  const [lng, setLang] = React.useState("en");
  const bastards = [
    { name: "By LOCATION", href: "/project-list/location" },
    { name: "By DOCUMENTOR", href: "/project-list/documentor" },
    { name: "By TYPE", href: "/project-list/type" },
  ];
  const classes = useStyles()

  const handleChange = (event) => {
    setLang(event.target.value);
  };


  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <footer>

      <Box>
        {/* <Box py={5}>
          <Divider />
        </Box> */}
        <Divider />
        <Box sx={{ paddingLeft: 6, paddingRight: 6, paddingTop: 4 }} className={classes.foot_nav}>
          <Grid container className="xs:pt:6 sm:pt:10">
            <Grid item xs={12} sm={4}>
              <Box mb={2}>
                <Link href="/" color="inherit" underline="none">
                  <Typography
                    variant="h5"
                    noWrap
                    component="div"
                    color="#000"
                    className="font-impact text-lg md:text-2xl my-4"
                  >
                    <span className="font-impact  text-lg md:text-2xl my-4 txt--default" style={{ letterSpacing: 1 }}>
                      BANGKOK BASTARDS
                    </span>
                  </Typography>
                </Link>
              </Box>
            </Grid>
            <Grid item xs={12} sm={8} container>
              <Grid item xs={12} sm={3}>
                <Box mb={2}>
                  <Link href="/" color="#000" underline="none">
                    HOME
                  </Link>
                </Box>
                <Box mb={2}>
                  <Button
                    disableFocusRipple
                    sx={{ padding: 0, fontSize: 16, color: "#000", fontWeight: 400 }}
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                  >
                    BASTARDS
                  </Button>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    {bastards.map(({ name, href }) => (
                      <Link key={name} color="inherit" underline="none" href={href}>
                        <MenuItem
                          onClick={handleClose}
                          divider={true}
                          style={{ minWidth: 200 }}
                        >
                          <Typography textAlign="center">{name}</Typography>
                        </MenuItem>
                      </Link>
                    ))}
                  </Menu>
                </Box>
                <Box mb={2}>
                  <Link href="/about" color="#000" underline="none">
                    ABOUT US
                  </Link>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box mb={2}>
                  <Link href="/" color="#000" underline="none">
                    CONTACT US
                  </Link>
                </Box>
                {/* <Box mb={2}>
                  <Link href="/" color="#000" underline="none">
                    <CallIcon /> 089-879-8756
                  </Link>
                </Box> */}
                <Box mb={2}>
                  <Link href="mailto:chatlab.bangkok@gmail.com" color="#000" underline="none" target={'blank'}>
                    <MailIcon /> chatlab.bangkok@gmail.com
                  </Link>
                </Box>
                <Box mb={2}>
                  <Link href="https://www.facebook.com/chatarchitects/" color="#000" underline="none" target={'blank'}>
                    <FacebookIcon /> chat architects
                  </Link>
                </Box>
              </Grid>
              <Grid item xs={6} sm={3} className={classes.lng_container}>
                <Box className="cnt-box">
                  <Typography color="#000" className="font-extrabold">LANGUAGE</Typography>
                  <FormControl fullWidth sx={{ marginTop: 1 }}>
                    <Select
                      value={lng}
                      onChange={handleChange}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      {/* <MenuItem value={"th"}>
                        <LanguageIcon /> TH
                      </MenuItem> */}
                      <MenuItem value={"en"}>
                        <LanguageIcon /> EN
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box className="sm:mt-24 xs:mt-0">
          <Divider />
        </Box>
        <Box sx={{ paddingLeft: 6, paddingRight: 6, marginBottom: 2, marginTop: 2 }} className={classes.foot_nav}>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 0, sm: 0 }}
            justifyContent="space-between"
          >
            <Typography variant="caption" color="#000">
            Copyright © {new Date().getFullYear()} Bangkok Bastards by Chat Architects
            </Typography>
            <Typography variant="caption" color="#000">
            background “Studio Lam Plearn” by The Paradise Bangkok Molam International Band 
            </Typography>
          </Stack>
          {/* <Box textAlign="left">
            <Typography variant="caption" color="#000">
              Bangkok Bastards &reg; {new Date().getFullYear()} CO., LTD 
            </Typography>
          </Box> */}
        </Box>
      </Box>
    </footer>
  );
}

import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { TitleContainer } from "../mainStyles";
import { ImageSlider, LayoutWithNavbar } from "../../components";
import { Divider, Icon } from "@mui/material";
import { ChevronRight } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { DUMMY_DATA_PROJECT_LISTS } from "../../constant";
import ScrollAnimation from "react-animate-on-scroll";
import { Animated } from "react-animated-css";
import { Helmet } from "react-helmet";
import {
  getProjectByTypes,
  getProjectByDocumenters,
  getProjectByMainArea,
} from "../../services/projectService";
export default function ProjectListScreen() {
  const navigate = useNavigate();
  const { category } = useParams();
  const [slides, setSlides] = useState([]);
  const [height, setHeight] = useState(0);
  const elmRef = useRef(null);
  useEffect(() => {
    const fetchSlidesData = async () => {
      let response = null;
      try {
        if (category === "documenter") {
          response = await getProjectByDocumenters();
        }
        if (category === "type") {
          response = await getProjectByTypes();
        }
        if (category === "location") {
          response = await getProjectByMainArea();
        }

        if (response && response?.success) {
          setSlides(response.data);
        }
      } catch (e) {
        console.log(e.message);
      }
    };
    fetchSlidesData();
    
    if(elmRef && elmRef.current){
      setHeight(elmRef.current.clientHeight)
    }
    const handleResize = () => setHeight(elmRef.current.clientHeight)
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  const handleNavProjectList = ({ type }) => {
    if (category === "location") {
      const typeSlug = type.replace(/\s/g, "-");
      navigate(`/project-list/location/${typeSlug.toLowerCase()}`);
    }
  };
  return (
    <LayoutWithNavbar>

      <Helmet>
        <title>BANGKOK BASTARDS | {category.toUpperCase()}</title>
        <meta name="keywords" content="Bangkok Bastards, Bangkok Bastard, CHAT architects,สถาปัตยกรรมชั้นเลว,, สถาปัตยกรรมที่ไม่น่าพิสมัย,บ้านคนงานก่อสร้าง,สลัม,แผงลอยเป็นสถาปัตยกรรมชั้นต่ำ,บ้านคนงานก่อสร้าง" />
        <meta name="author" content="CHAT architects" />
        <meta name="description" content="Bangkok Bastards describes the research subjects of CHAT architects. From construction worker houses to forgotten shantytowns, from illegal pop-up markets to street vendor carts, from seedy massage parlors to underground sex motels, these vernacular Bastards are hybrids of questionable origins, scattered though out the city." />
        <meta itemprop="name" content="สถาปัตยกรรมสารเลว กับมุมมองที่แตกต่างของคุณฉัตรพงษ์ ชื่นฤดีมล&nbsp;แห่ง CHAT Architects" />
        <meta itemprop="headline" content="สถาปัตยกรรมสารเลว กับมุมมองที่แตกต่างของคุณฉัตรพงษ์ ชื่นฤดีมล&nbsp;แห่ง CHAT Architects" />
        <meta itemprop="image" content="https://bangkokbastards.s3.ap-southeast-1.amazonaws.com/1660290435772-share.jpg" />
        <meta property="og:url"  content="https://www.bangkokbastards.com" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={`BANGKOK BASTARDS | ${category.toUpperCase()}`} />
        <meta property="og:description" content="Bangkok Bastards describes the research subjects of CHAT architects. From construction worker houses to forgotten shantytowns, from illegal pop-up markets to street vendor carts, from seedy massage parlors to underground sex motels, these vernacular Bastards are hybrids of questionable origins, scattered though out the city" />
        <meta property="og:image" content="https://bangkokbastards.s3.ap-southeast-1.amazonaws.com/1660290435772-share.jpg" />
      </Helmet>
      <div
        className="flex flex-row justify-center text-5xl sm:text-7xl  md:text-8xl lg:text-9xl font-impact sticky-header"
        ref={elmRef}
      >
        <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={true}>
          <p className="text-secondary">
            BY <span className="text-primary">{category.toUpperCase()}</span>
          </p>
        </Animated>
      </div>
      <div className="list-content-wrapper" style={{paddingTop: height}}>
        {slides.map((item, index) => {
          return (
            <div key={index} className="my-5 md:my-12">
              <TitleContainer
                className="cursor-pointer items-center"
                onClick={() => handleNavProjectList(item)}
              >
                <Animated
                  animationIn="fadeIn"
                  animationOut="fadeOut"
                  isVisible={true}
                >
                  {category === "location" && (
                    <p className="text-primary">
                      {item.type}
                      <span className="text-secondary"> BASTARDS</span>
                    </p>
                  )}
                  {category === "type" && (
                    <p className="text-secondary">
                      BASTARD<span className="text-primary"> {item.type}</span>
                    </p>
                  )}
                  {category === "documenter" && (
                    <p>
                      <span className="text-secondary"> {item.type}</span>
                    </p>
                  )}
                </Animated>
                {category === "location" && (
                  <Icon
                    className="ml-2"
                    component={ChevronRight}
                    fontSize="inherit"
                  />
                )}
              </TitleContainer>

              <ImageSlider data={item.projects} />
            </div>
          );
        })}
      </div>
    </LayoutWithNavbar>
  );
}

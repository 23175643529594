import React from 'react'
import { LeftArrowContainer, ContainerIcon } from '../styles'
import { ChevronLeft } from '@mui/icons-material';
import { Icon } from '@mui/material';

let interval
function LeftArrow(props) {
  React.useEffect(() => {
    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [])
  const { onClick } = props
  const onHover = () => {
    interval = setInterval(onClick, 900)
  }
  return (
    <LeftArrowContainer
      className="w-12"
      onMouseEnter={onHover}
      onMouseLeave={() => {
        if (interval) {
          clearInterval(interval)
        }
      }}
      onClick={onClick}
    >
      <ContainerIcon>
        <Icon component={ChevronLeft} fontSize="large" />
      </ContainerIcon>
    </LeftArrowContainer>)
}

export default React.memo(LeftArrow)
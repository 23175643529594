import React, { useState, useCallback } from "react";
import { Title, TBox, TTextBox, TVideoBox, TMultiBox, TUniversalBox, TUniversalBoxTitle } from "../styles";
import useWindowDimensions from "../../../hook/Dimension";
import MediaOnlyDialog from '../../ModalWidgets/MediaOnlyDialog'
import MediaTextDialog from '../../ModalWidgets/MediaTextDialog'
import ViewMoreDialog from "../../ModalWidgets/ViewMoreDialog";

/*Template One*/
import ImageTitle from '../../../assets/images/template1/c1.png'
import ImageContent1 from '../../../assets/images/template1/c2.png'
import ImageContent2 from '../../../assets/images/template1/c3.png'
import ImageContent3 from '../../../assets/images/template1/c4.png'
import ImageContent4 from '../../../assets/images/template1/c5.png'
import {
  PLACE_HOLDER_C1,
  PLACE_HOLDER_C2,
  PLACE_HOLDER_C3,
  PLACE_HOLDER_C4,
  PLACE_HOLDER_TITLE
} from "../../TemplateWidgets/constant";

export default function T1(props) {
  const { form, templateGalleries = [], preview, previewSelectHandler, isAdmin } = props
  const { width } = useWindowDimensions();

  const isMobileSize = width < 640
  const isResponsive = isMobileSize && !isAdmin;

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openMediaText, setOpenMediaText] = useState(false);
  const handleMediaTextOpen = () => setOpenMediaText(true);
  const handleMediaTextClose = () => setOpenMediaText(false);
  const [contentId, setContentId] = useState(0)


  const contentClickHandler = useCallback((contentId = 0) => {
    if (!preview) {
      contentId ? handleMediaTextOpen() : handleOpen()
      setContentId(contentId)
      return;
    }
    previewSelectHandler(templateGalleries[contentId])
  }, [preview])

  return (
    <>
      <ViewMoreDialog />
      {
        !preview && <>
          <MediaOnlyDialog
            form={form}
            handleClickOpen={handleOpen}
            open={open}
            handleClose={handleClose}
            templateId={1}
          />
          <MediaTextDialog
            form={form}
            handleClickOpen={handleOpen}
            openMediaText={openMediaText}
            handleMediaTextClose={handleMediaTextClose}
            contentId={contentId}
            templateId={1}
          />
        </>
      }
      {/* BOB New Template */}
      {/* TITLE */}
      <div style={{ marginBottom: 8, position: 'relative' }} onClick={() => contentClickHandler(0)}>
        <img src={ImageTitle} className="img-base" alt="Title" is-admin={isAdmin ? "true" : "false"} />
        <TUniversalBoxTitle
          isAdmin={isAdmin}
          url={templateGalleries[0]?.url_desktop ?
            templateGalleries[0]?.url_desktop : PLACE_HOLDER_TITLE}
          mbUrl={
            templateGalleries[0]?.url_mobile ?
              templateGalleries[0]?.url_mobile :
              templateGalleries[0]?.url_desktop || PLACE_HOLDER_TITLE
          }
        />
      </div>

      <div style={{ display: 'flex', flexDirection: isResponsive ? 'column' : 'row' }}>
        <div style={{ position: 'relative' }} className="right-gutter">
          <img src={ImageContent1} className="img-base" alt="Content 1" is-admin={isAdmin ? "true" : "false"} />
          <TUniversalBox
            useDesktop={templateGalleries[1]?.use_desktop}
            isAdmin={isAdmin}
            type={templateGalleries[1]?.filetype}
            url={
              templateGalleries[1]?.url_desktop ?
                templateGalleries[1]?.url_desktop :
                PLACE_HOLDER_C1
            }
            mbUrl={
              templateGalleries[1]?.url_mobile ?
                templateGalleries[1]?.url_mobile :
                templateGalleries[1]?.url_desktop || PLACE_HOLDER_C1
            }

            onClick={() => contentClickHandler(1)}
            title={templateGalleries[1]?.title_en}
            description={templateGalleries[1]?.description_en}
            className="clamp-lg"
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', flexDirection: isResponsive ? 'column' : 'row' }}>
            <div style={{ position: 'relative' }}>
              <img src={ImageContent2} className="img-base" alt="Content 2" is-admin={isAdmin ? "true" : "false"} />
              <TUniversalBox
                useDesktop={templateGalleries[2]?.use_desktop}
                isAdmin={isAdmin}
                type={templateGalleries[2]?.filetype}
                url={
                  templateGalleries[2]?.url_desktop ?
                    templateGalleries[2]?.url_desktop :
                    PLACE_HOLDER_C2}
                mbUrl={
                  templateGalleries[2]?.url_mobile ?
                    templateGalleries[2]?.url_mobile :
                    templateGalleries[2]?.url_desktop || PLACE_HOLDER_C2
                }
                onClick={() => contentClickHandler(2)}
                title={templateGalleries[2]?.title_en}
                description={templateGalleries[2]?.description_en}
                className="clamp-md"
              />
            </div>
            <div style={{ position: 'relative' }} className="left-gutter">
              <img src={ImageContent3} className="img-base" alt="Content 3" is-admin={isAdmin ? "true" : "false"} />
              <TUniversalBox
                useDesktop={templateGalleries[3]?.use_desktop}
                isAdmin={isAdmin}
                type={templateGalleries[3]?.filetype}
                url={
                  templateGalleries[3]?.url_desktop ?
                    templateGalleries[3]?.url_desktop :
                    PLACE_HOLDER_C3}
                mbUrl={
                  templateGalleries[3]?.url_mobile ?
                    templateGalleries[3]?.url_mobile :
                    templateGalleries[3]?.url_desktop || PLACE_HOLDER_C3
                }
                flex={1.2}
                onClick={() => contentClickHandler(3)}
                title={templateGalleries[3]?.title_en}
                description={templateGalleries[3]?.description_en}
                className="clamp-lg"
              />
            </div>
          </div>
          <div style={{ marginTop: 8, position: 'relative' }}>
            <img src={ImageContent4} className="img-base" alt="Content 4" is-admin={isAdmin ? "true" : "false"} />
            <TUniversalBox
              isAdmin={isAdmin}
              useDesktop={templateGalleries[4]?.use_desktop}
              type={templateGalleries[4]?.filetype}
              url={
                templateGalleries[4]?.url_desktop ?
                  templateGalleries[4]?.url_desktop :
                  PLACE_HOLDER_C4}
              mbUrl={
                templateGalleries[4]?.url_mobile ?
                  templateGalleries[4]?.url_mobile :
                  templateGalleries[4]?.url_desktop || PLACE_HOLDER_C4
              }
              onClick={() => contentClickHandler(4)}
              title={templateGalleries[4]?.title_en}
              description={templateGalleries[4]?.description_en}
              className="clamp-md"
            />
          </div>
        </div>

      </div>

      {/* <div
        style={{
          width: widthPercentage,
          height: isResponsive ? "auto" : widthPercentage * 0.84,
          overflow: isResponsive ? "auto" : "hidden",
        }}
      >

        <div key="title" onClick={handleOpen}>
          <Title
            isAdmin={isAdmin}
            width={widthPercentage}
            height={widthPercentage * 0.224}
            url={templateGalleries[0]?.url_desktop ?
              templateGalleries[0]?.url_desktop : PLACE_HOLDER_TITLE}
            mbUrl={
              templateGalleries[0]?.url_mobile ?
                templateGalleries[0]?.url_mobile :
                templateGalleries[0]?.url_desktop || PLACE_HOLDER_TITLE
            }
          />
        </div>
        <div
          style={{
            width: widthPercentage,
            height: isResponsive ? "auto" : widthPercentage * 0.62,
            backgroundColor: "#f3f3f3",
            flexDirection: isResponsive ? "column" : "row",
            display: "flex",
            justifyContent: "stretch",
          }}
        >
          <TMultiBox
            isAdmin={isAdmin}
            type={templateGalleries[1]?.filetype}
            url={
              templateGalleries[1]?.url_desktop ?
                templateGalleries[1]?.url_desktop :
                PLACE_HOLDER_C1
            }
            mbUrl={
              templateGalleries[1]?.url_mobile ?
                templateGalleries[1]?.url_mobile :
                templateGalleries[1]?.url_desktop || PLACE_HOLDER_C1
            }

            onClick={() => contentClickHandler(1)}
            title={templateGalleries[1]?.title}
            description={templateGalleries[1]?.description}
            flex={1.6}
            className="t1c1"
          />
          <div style={{ flex: 3, flexDirection: "column", display: "flex" }}>
            <div
              style={{
                flex: 4.6,
                flexDirection: isResponsive ? "column" : "row",
                display: "flex",
                justifyContent: "stretch",
              }}
            >

              <TMultiBox
                isAdmin={isAdmin}
                type={templateGalleries[2]?.filetype}
                url={
                  templateGalleries[2]?.url_desktop ?
                    templateGalleries[2]?.url_desktop :
                    PLACE_HOLDER_C2}
                mbUrl={
                  templateGalleries[2]?.url_mobile ?
                    templateGalleries[2]?.url_mobile :
                    templateGalleries[2]?.url_desktop || PLACE_HOLDER_C2
                }
                onClick={() => contentClickHandler(2)}
                title={templateGalleries[2]?.title}
                description={templateGalleries[2]?.description}
                flex={3}
                className="t1c2"
              />
              <TMultiBox
                isAdmin={isAdmin}
                type={templateGalleries[3]?.filetype}
                url={
                  templateGalleries[3]?.url_desktop ?
                    templateGalleries[3]?.url_desktop :
                    PLACE_HOLDER_C3}
                mbUrl={
                  templateGalleries[3]?.url_mobile ?
                    templateGalleries[3]?.url_mobile :
                    templateGalleries[3]?.url_desktop || PLACE_HOLDER_C3
                }
                flex={1.2}
                onClick={() => contentClickHandler(3)}
                title={templateGalleries[3]?.title}
                description={templateGalleries[3]?.description}
                className="t1c3"
              />

            </div>

            <TMultiBox
              isAdmin={isAdmin}
              type={templateGalleries[4]?.filetype}
              url={
                templateGalleries[4]?.url_desktop ?
                  templateGalleries[4]?.url_desktop :
                  PLACE_HOLDER_C4}
              mbUrl={
                templateGalleries[4]?.url_mobile ?
                  templateGalleries[4]?.url_mobile :
                  templateGalleries[4]?.url_desktop || PLACE_HOLDER_C4
              }
              onClick={() => contentClickHandler(4)}
              title={templateGalleries[4]?.title}
              description={templateGalleries[4]?.description}
              flex={1.6}
              className="t1c4"
            />
          </div>
        </div>
      </div> */}
    </>
  );
}
